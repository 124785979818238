import React, { useState, useEffect } from 'react';

// mui components

// core component
import VendorResponseInformation from './VendorReadOnly/VendorResponseInformation';

// redux
import { connect } from 'react-redux';
import { GetOneVendor, GetAllResponses } from 'store/actions/vendorActions';

import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';

const ReadOnlyVendor = (props) => {
    // props
    const { GetAllResponses, all_responses, open, handleClose, merchant, goBack, vendor } = props;
    const { id } = useParams();

    //state
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(true);
    const [activeTab, setActiveTab] = useState(1);

    //functions
    const getOneVendor = async () => {
        setLoading({ ...loading, content: true });
        const res = await GetOneVendor();
        setLoading({ ...loading, content: false });
        if (!res?.success) {
            toast.error(res?.message);
        }
    };

    const getAllResponses = async () => {
        setLoading((loading) => ({ ...loading, content: true }));
        const res = await GetAllResponses();
        setLoading((loading) => ({ ...loading, content: false }));
        if (!res?.success) {
            toast.error(res?.message);
        }
    };

    useEffect(() => {
        getOneVendor();
        getAllResponses();
    }, []);
    useEffect(() => {
        if (all_responses) {
            const vendorResponse = all_responses?.filter(
                (response) => response?.vendor_details?.id === JSON?.parse(vendor)
            );
            // console.log({ vendorResponse, vendor });
            // setActiveTab(vendorResponse?.[0]?.id);
            setActiveTab(vendorResponse?.sort((a, b) => a?.id - b?.id)?.[0]?.id);
        }
    }, [all_responses]);

    return (
        <div>
            <VendorResponseInformation
                merchant={merchant}
                page={page}
                setPage={setPage}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                open={open}
                vendor={id}
                handleClose={handleClose}
                goBack={goBack}
            />
        </div>
    );
};
const mapStateToProps = (state) => {
    return {
        ...state,
        one_vendor: state?.vendorReducers?.one_vendor,
        all_responses: state?.vendorReducers?.all_responses,
        merchant: state?.generalReducers?.user_info?.organization?.id,
    };
};
export default connect(mapStateToProps, { GetOneVendor, GetAllResponses })(ReadOnlyVendor);
