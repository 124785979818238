/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { Typography, Box, ButtonGroup, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import fileQuestion from 'assets/img/file-question.svg';
import FileTypes from 'assets/img/Filetypes.svg';
import Docx from 'assets/img/docx.svg';
import Png from 'assets/img/png.svg';
import Xsl from 'assets/img/xsl.svg';
import Img from 'assets/img/img.svg';
import Download from 'assets/img/download01.svg';
import axiosInstance from 'utils/https';
import { toast } from 'react-toastify';
import { Loader2 } from 'lucide-react';

const ViewResponse = (props) => {
    const [isLoading, setIsLoading] = useState(false);

    const theme = useTheme();
    const { name, answer, type, options } = props;
    let bulletedName = name.substr(name.indexOf(' ') + 1);

    async function getDocumentSignedURL(documentName) {
        try {
            setIsLoading(true);
            const response = await axiosInstance().get(`/compliance/signed_url/?object_key=${documentName}`);
            window.open(response.url, '_blank');
        } catch (error) {
            console.log(error);
            toast.error('Failed to get signed URL');
        } finally {
            setIsLoading(false);
        }
    }

    // console.log({ name, answer, type, options });

    return (
        <div className="py-3">
            <Box sx={{ display: 'flex', alignItems: 'start' }}>
                <img src={fileQuestion} alt="fileQuestion" />
                <Typography sx={{ ml: 1, color: '#64748B', fontSize: '14px', fontWeight: 400 }}>
                    {bulletedName}
                </Typography>
            </Box>
            {type === 'dropdown' ? (
                <div>
                    <ButtonGroup sx={{ my: 1.5 }}>
                        {options.split(',')?.map((option, index) => (
                            <Button
                                key={index}
                                sx={{
                                    backgroundColor:
                                        answer?.[0]?.trim() === option?.trim()
                                            ? '#94A3B8'
                                            : answer?.includes(option?.trim())
                                            ? '#94A3B8'
                                            : '',
                                    color:
                                        answer?.[0]?.trim() === option?.trim()
                                            ? '#FFFFFF'
                                            : answer?.includes(option?.trim())
                                            ? '#FFFFFF'
                                            : '#64748B',
                                    border:
                                        answer?.[0]?.trim() === option?.trim()
                                            ? 'none'
                                            : answer?.includes(option?.trim())
                                            ? 'none'
                                            : '0.5px solid #E2E8F0',
                                    px: 0.7,
                                    '&:hover': {
                                        backgroundColor:
                                            answer?.[0]?.trim() === option?.trim()
                                                ? '#94A3B8'
                                                : answer?.includes(option?.trim())
                                                ? '#94A3B8'
                                                : '',
                                        color:
                                            answer?.[0]?.trim() === option?.trim()
                                                ? '#FFFFFF'
                                                : answer?.includes(option?.trim())
                                                ? '#FFFFFF'
                                                : '#64748B',
                                    },
                                }}
                            >
                                {option}
                            </Button>
                        ))}
                    </ButtonGroup>
                    {answer?.[0]?.trim() === 'Yes' && Boolean(answer?.[1]) && (
                        <div className="flex items-center gap-3">
                            <img
                                src={
                                    answer?.[1]?.split('.').pop() === 'xlsx'
                                        ? Xsl
                                        : answer?.[1]?.split('.').pop() === 'xls'
                                        ? Xsl
                                        : answer?.[1]?.split('.').pop() === 'docx'
                                        ? Docx
                                        : answer?.[1]?.split('.').pop() === 'doc'
                                        ? Docx
                                        : answer?.[1]?.split('.').pop() === 'png'
                                        ? Png
                                        : answer?.[1]?.split('.').pop() === 'img'
                                        ? Img
                                        : FileTypes
                                }
                                alt="FileTypes"
                            />
                            <Typography
                                component="p"
                                sx={{
                                    color: '#475569',
                                    fontSize: '14px',
                                    fontWeight: 400,
                                    // maxWidth: '300px !important',
                                    wordWrap: 'break-word',
                                }}
                            >
                                {answer?.[1]?.split('/')?.pop()}
                            </Typography>
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    mr: 2,
                                    cursor: isLoading ? 'not-allowed' : 'pointer',
                                    pointerEvents: isLoading ? 'none' : 'auto',
                                }}
                                onClick={() => getDocumentSignedURL(answer?.[1]?.split('.com/')?.pop())}
                            >
                                {' '}
                                {isLoading ? (
                                    <Loader2 className="animate-spin w-4 h-4" />
                                ) : (
                                    <img src={Download} alt={'Download'} />
                                )}
                            </Box>
                        </div>
                    )}
                </div>
            ) : (
                <Typography
                    variant="h3"
                    noWrap
                    component="h3"
                    sx={{
                        m: 0,
                        pl: { xs: '33px', md: '52px' },
                        pr: 2,
                        pt: 1,
                        fontSize: { xs: '12px', md: '14px' },
                        color: ' #919094',
                        fontWeight: '600',
                    }}
                >
                    {answer?.includes('https:') ? (
                        <Box
                            sx={{
                                color: theme.palette.primary[900],
                                textDecoration: 'underline',
                                paddingRight: '5px',
                                cursor: 'pointer',
                            }}
                            component="a"
                            href={answer}
                            download
                        >
                            Click here to download
                        </Box>
                    ) : (
                        answer
                    )}
                </Typography>
            )}
        </div>
    );
};

export default ViewResponse;
