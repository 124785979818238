import ViewThreatDrawer from 'components/Dashboard/components/firstSection/ViewThreatIntelligence';
import { useSmartIntelContext } from 'store/context/SmartIntelContextProvider';

export default function SmartIntel({ children }) {
    const { open, handleClose, currentData, currentTag, type, handleViewThreat } = useSmartIntelContext();

    return (
        <>
            {children}
            <ViewThreatDrawer
                open={open}
                handleClose={handleClose}
                currentData={currentData}
                type={type}
                currentTag={currentTag}
                handleViewThreat={handleViewThreat}
            />
        </>
    );
}
