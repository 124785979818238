import { Stack, Box, Typography } from '@mui/material';
import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import WestIcon from '@mui/icons-material/West';

// import { useTheme } from '@mui/material/styles';
import ResponseItemContainer from './Components/ResponseItemContainer';
import ResponseBody from './Components/components/ResponseBody';
import VendorScoreCard from './Components/components/VendorScoreCard';
// import ResponseHeader from './Components/components/ResponseHeader';

const VendorResponseView = (props) => {
    const merchant = useSelector((state) => state?.authReducers?.company_details);
    // const theme = useTheme();
    const {
        questionnaire_forms,
        all_responses,
        activeTab,
        setActiveTab,
        vendor,
        handleChangeView,
        goBack,
        loading = { loading },
    } = props;

    const currentResponse = useMemo(() => {
        return all_responses?.find((response) => response?.id === activeTab);
    }, [activeTab]);
    const currentQuestion = useMemo(() => {
        return questionnaire_forms?.find((question) => question?.title === currentResponse?.form_title);
    }, [currentResponse]);
    const [openScorecard, setOpenScorecard] = useState(false);

    const openScoreCard = () => {
        setOpenScorecard(true);
    };

    const handleClose = () => {
        setOpenScorecard(false);
    };
    const vendorResponse = all_responses?.filter((response) => response?.vendor_details?.id === JSON?.parse(vendor));

    return (
        <Box sx={{ height: '100vh' }}>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    backgroundColor: '#FFFFFF',
                    borderBottom: '1px solid #F1F5F9',
                    px: 2,
                    py: 1,
                    height: '7vh',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <WestIcon color="#64748B" onClick={goBack} sx={{ cursor: 'pointer' }} />
                    <Typography sx={{ color: '#202D66', fontSize: '18px', fontWeight: 700, ml: 1.5 }}>
                        Vendor response ({currentResponse?.vendor_details?.name || merchant?.name})
                    </Typography>
                </Box>
                <Typography
                    sx={{
                        backgroundColor: '#F1F5F9',
                        color: '#202D66',
                        fontSize: '13px',
                        fontWeight: 500,
                        textTransform: 'inherit',
                        px: 1.5,
                        py: 1,
                        borderRadius: 1,
                        cursor: 'pointer',
                    }}
                    onClick={openScoreCard}
                >
                    View score card
                </Typography>
            </Box>
            <Box
                sx={{
                    // height: 'calc(100vh - 114px)',
                    width: 'calc(100% + 48px)',
                    display: 'flex',
                    alignItems: 'center',
                    ml: '-24px',
                    // mt: '-24px',
                }}
            >
                <Stack
                    direction="row"
                    sx={{
                        width: '100%',
                        overflow: 'auto',
                        height: '93vh',
                    }}
                >
                    <Box
                        component="nav"
                        sx={{
                            width: { md: 290 },
                            flexShrink: { md: 0 },
                        }}
                    >
                        <ResponseItemContainer
                            largeScreen={true}
                            all_questions={vendorResponse}
                            activeTab={activeTab}
                            setActiveTab={setActiveTab}
                            handleChangeView={handleChangeView}
                        />
                    </Box>
                    <Stack
                        sx={{
                            height: '90vh',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        {/* <ResponseHeader
                            id={currentResponse?.id}
                            key={currentResponse?.id}
                            activeTab={activeTab}
                            title={currentResponse?.form_title}
                            value={currentResponse?.score_percent}
                            openDrawer={openDrawer}
                            score_percent={currentResponse?.score_percent}
                            risk_level={currentResponse?.risk_level}
                        /> */}
                        <ResponseBody
                            key={currentQuestion?.id}
                            activeTab={activeTab}
                            title={currentResponse?.form_title}
                            questionID={currentQuestion?.id}
                            question={currentQuestion}
                            answers={currentResponse?.answer}
                            score_percent={currentResponse?.score_percent}
                            risk_level={currentResponse?.risk_level}
                        />
                    </Stack>
                </Stack>
                <VendorScoreCard
                    open={openScorecard}
                    handleClose={handleClose}
                    vendor={vendor}
                    questionnaire_forms={questionnaire_forms}
                />
            </Box>
        </Box>
    );
};

export default VendorResponseView;
