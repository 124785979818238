import React from 'react';
import DoubleRightCheck from 'assets/img/chevron-right-double.svg';
import Carousel from 'components/new_components/carousel/Carousel';
import SmartIntelLogo from 'assets/img/Smartcomply Intel fav 1.svg';
import ArrowRight from 'assets/img/arrow-up-right.svg';
import { useSelector } from 'react-redux';
import AppLinearProgress from 'components/new_components/AppLinearProgress';
import { getCompliancePercentage } from 'utils';
// import FileIcon from 'assets/img/file_icon.png';
import { useHistory } from 'react-router-dom';

const Frameworks = (props) => {
    const { switch_on, handleOpen } = props;
    const merchant_info = useSelector((state) => state?.merchantReducers?.merchant_info);
    const { dashboardResult } = useSelector((state) => state?.dashboardReducers);
    const { all_smart_intel } = useSelector((state) => state?.generalReducers);
    const filtered_standards = merchant_info?.standards
        ?.split(',')
        ?.filter((standard) => standard !== 'soc2type2' && standard !== 'pcislc');
    const keywords = merchant_info?.intel_keywords?.['Compliance'];

    const router = useHistory();
    const gotoPage = (link) => {
        router.push(link);
    };
    const compliance_intel =
        switch_on && all_smart_intel?.Compliance
            ? Object?.values(all_smart_intel?.Compliance)
                  ?.map((option) => option)
                  ?.flat()
                  ?.filter((el) => {
                      return keywords?.some(
                          (keyword) =>
                              el?.title?.toLowerCase()?.includes(keyword) ||
                              el?.market_place?.toLowerCase()?.includes(keyword)
                      );
                  })
            : [];

    // console.log({ dashboardResult, filtered_standards });
    return (
        <div className="bg-white border-1 border-[#F1F5F9] rounded-md p-3">
            <div className="flex justify-between items-center">
                <h4 className="text-[#2B3674] text-lg font-semibold">Frameworks</h4>
                <button
                    className="bg-[#F1F5F9] py-1 px-2 rounded-md"
                    onClick={() => gotoPage('/merchant/compliance/frameworks')}
                >
                    <img src={DoubleRightCheck} alt="DoubleRightCheck" />
                </button>
            </div>
            {switch_on && compliance_intel?.length ? (
                <div className=" my-[1.5rem] transition ease-in-out duration-500">
                    <div className="bg-[#E5F1FF] rounded-full px-3 py-2 flex justify-between items-center 2xl:h-[50px] xl:h-[70px] md:[100px]">
                        <div className="flex items-start !h-[36px] gap-2 w-[85%]">
                            <img src={SmartIntelLogo} alt="SmartIntelLogo" className="xl:block md:hidden" />
                            <p className="bg-[#F43F5E] text-white text-[13px] rounded px-1 py-0 w-fit xl:block md:hidden">
                                {compliance_intel?.length}
                            </p>
                            <Carousel
                                slides={compliance_intel}
                                handleOpen={handleOpen}
                                type="Compliance"
                                data={all_smart_intel?.Compliance}
                            />
                        </div>
                        <img src={ArrowRight} alt="ArrowRight" />
                    </div>
                </div>
            ) : null}
            <div className={`${switch_on ? 'mt-[1rem]' : 'mt-[1rem]'}`}>
                {filtered_standards?.slice(0, switch_on ? 3 : 4)?.map((standard, index) => (
                    <div key={index} className="mb-4">
                        <h4 className="text-[#395BA9] text-sm font-bold uppercase">{standard}</h4>
                        <AppLinearProgress
                            value={getCompliancePercentage(dashboardResult, standard)}
                            sx={{ my: 1, color: '#395BA9' }}
                        />
                        <div
                            className="flex justify-end items-center cursor-pointer"
                            onClick={() => gotoPage(`/merchant/scans/${standard}`)}
                        >
                            {/* <div className="flex items-center gap-1">
                                <img src={FileIcon} alt="FileIcon" />
                                <p className="text-[#475569] text-[11px] font-medium">
                                    3/11{' '}
                                    <span className="text-[#64748B] text-[11px] font-normal">Control Objective</span>
                                </p>
                            </div> */}
                            <p className="text-[#64748B] text-[11px] font-semibold">
                                {Math.ceil(getCompliancePercentage(dashboardResult, standard))}%{' '}
                                <span className="text-[#64748B] text-[11px] font-normal">Done</span>
                            </p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};
export default Frameworks;
