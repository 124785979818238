import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Modal, Row, Col, Input, Button } from 'antd';
import { useDispatch } from 'react-redux';

const { TextArea } = Input;

const SubcontrolsModal = (props) => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({});
    const dispatch = useDispatch();
    const { open, handleCancel, one_subcontrol, CreateControl, parent_control_id, EditControl } = props;

    // function
    const handleTextChange = (e) => {
        const { name, value } = e.target;
        setData({ ...data, [name]: value });
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        const res =
            open === 'Add'
                ? await dispatch(CreateControl({ ...data, parent_compliance: parent_control_id }))
                : await dispatch(EditControl({ ...data, parent_compliance: parent_control_id }, one_subcontrol.id));
        setLoading(false);
        if (res.success) {
            setData({});
            handleCancel();
            open === 'Add'
                ? toast.success('Sub management Added Successfully')
                : toast.success('Sub management Updated Successfully');
        } else {
            toast.error(res?.message);
        }
    };

    // useEffect
    useEffect(() => {
        if (one_subcontrol) {
            setData(one_subcontrol);
        } else {
            setData({});
        }
    }, [one_subcontrol]);
    return (
        <Modal title={`${open} Sub Management`} open={open} onCancel={handleCancel} footer={null} destroyOnClose={true}>
            <form onSubmit={handleSubmit}>
                <Row justify="end">
                    {' '}
                    <Col span={24}>
                        <div className="form-group" style={{ marginBottom: '1rem' }}>
                            <label htmlFor="number">Management Number</label>
                            <Input
                                type="number"
                                size="large"
                                name="number"
                                id="number"
                                onChange={handleTextChange}
                                value={data.number || ''}
                                required
                            />
                        </div>
                    </Col>
                    <Col span={24} className="my-2" style={{ marginBottom: '1rem' }}>
                        <label htmlFor="title">Management Title</label>
                        <Input
                            type="text"
                            size="large"
                            name="title"
                            id="title"
                            onChange={handleTextChange}
                            value={data.title || ''}
                            required
                        />
                    </Col>
                    <Col span={24} className="my-2" style={{ marginBottom: '1rem' }}>
                        <label htmlFor="description">Management Description</label>
                        <TextArea
                            type="text"
                            name="description"
                            id="description"
                            onChange={handleTextChange}
                            value={data.description || ''}
                            rows={4}
                            size="large"
                            required
                        />
                    </Col>
                    <Col>
                        <Button className="ant-btn" htmlType="submit" type="primary" loading={loading}>
                            Submit
                        </Button>
                    </Col>
                </Row>
            </form>
        </Modal>
    );
};

export default SubcontrolsModal;
