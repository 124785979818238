import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
//Core components
import { Box } from '@mui/material';
import AppTable from 'components/new_components/app-table';
import AppTag from 'components/new_components/AppTags';
import { useTheme } from '@mui/material/styles';
import useSearch from 'hooks/useSearch';
//redux
import { connect, useSelector } from 'react-redux';
import { GetAllComputers } from 'store/actions/merchantActions';

//utils
import { formatDateObjectHandler, GetFromNowDate } from 'utils';
import PageHeader from 'components/new_components/PageHeader';

//translations
import { useTranslation } from 'react-i18next';

import { useSmartIntelContext } from 'store/context/SmartIntelContextProvider';
import Carousel from 'components/new_components/carousel/Carousel';
import SmartIntelLogo from 'assets/img/Smartcomply Intel fav 1.svg';
import ArrowRight from 'assets/img/arrow-up-right.svg';
import SmartIntel from 'components/new_components/SmartIntel';

const NO_PER_PAGE = 8;

const Computers = (props) => {
    //state
    const [loading, setloading] = useState(false);
    const [page, setPage] = useState(1);
    const [pageData, setPageData] = useState([]);
    const { GetAllComputers, all_computers } = props;

    const { all_smart_intel } = useSelector((state) => state?.generalReducers);
    const merchant_info = useSelector((state) => state?.merchantReducers?.merchant_info);

    //theme
    const theme = useTheme();

    const { data, handleSearch } = useSearch(all_computers, [
        'name',
        'serialnumber',
        'systemname',
        'date_updated',
        'riskscore',
    ]);

    //translation
    const { t } = useTranslation('inventory');

    // functions
    const handlePageChange = (page) => {
        setPage(page);
    };

    const columns = [
        {
            title: t('computerTable.column1'),
            key: 'owner',
            render: (row) => <>{row?.name}</>,
        },
        {
            title: t('computerTable.column2'),
            key: 'serialnumber',
            render: (row) => <>{row?.serialnumber}</>,
        },
        {
            title: t('computerTable.column3'),
            key: 'systemname',
            render: (row) => <>{row?.systemname}</>,
        },
        {
            title: t('computerTable.column4'),
            key: 'date_updated',
            render: (row) => (
                <>
                    {formatDateObjectHandler(row.date_updated, 'MMMM Do, YYYY')}
                    <Box
                        component="div"
                        sx={{
                            color: theme.palette.gray[500],
                            ml: 0.2,
                            fontSize: '0.7rem',
                            fontWeight: 400,
                        }}
                    >
                        ({GetFromNowDate(row.date_updated, 'YYYY-MM-DDTHH:mm:ss', 'fromNow')})
                    </Box>
                </>
            ),
        },
        {
            title: t('computerTable.column5'),
            key: 'pwmanager',
            render: (row) => (
                <>
                    <AppTag
                        text={row.pwmanager ? 'Pass' : 'Failed'}
                        type={row.pwmanager ? 'success' : 'failed'}
                        hasIcon={true}
                    />
                </>
            ),
        },
        {
            title: t('computerTable.column6'),
            key: 'diskencryption',
            render: (row) => (
                <>
                    <AppTag
                        text={row.diskencryption ? 'Pass' : 'Failed'}
                        type={row.diskencryption ? 'success' : 'failed'}
                        hasIcon={true}
                    />
                </>
            ),
        },
        {
            title: t('computerTable.column7'),
            key: 'avstatus',
            render: (row) => (
                <>
                    <AppTag
                        text={row.avstatus ? 'Pass' : 'Failed'}
                        type={row.avstatus ? 'success' : 'failed'}
                        hasIcon={true}
                    />
                </>
            ),
        },
        {
            title: t('computerTable.column8'),
            key: 'lockstatus',
            render: (row) => (
                <>
                    <AppTag
                        text={row.lockstatus ? t('computerTable.pass') : t('computerTable.failed')}
                        type={row.lockstatus ? 'success' : 'failed'}
                        hasIcon={true}
                    />
                </>
            ),
        },
    ];

    useEffect(() => {
        const getAllComputers = async () => {
            setloading(true);
            const res = await GetAllComputers();
            if (res?.success) {
                setloading(false);
            } else {
                setloading(false);
                toast.error(res?.message);
            }
        };
        getAllComputers();
    }, []);

    useEffect(() => {
        const start = (page - 1) * NO_PER_PAGE;
        const stop = start + NO_PER_PAGE;
        setPageData(data?.length > 0 ? data?.sort((a, b) => b?.id - a?.id)?.slice(start, stop) : []);

        return data;
    }, [page, data]);

    // smart intel

    const keywords = merchant_info?.intel_keywords?.['People & Computers'];
    const { handleOpen } = useSmartIntelContext();
    const switch_on = merchant_info?.smart_intel;
    const intel =
        switch_on && all_smart_intel?.['People & Computers']
            ? Object?.values(all_smart_intel?.['People & Computers'])
                  ?.map((option) => option)
                  ?.flat()
                  ?.filter((el) => {
                      return keywords?.some(
                          (keyword) =>
                              el?.title?.toLowerCase()?.includes(keyword) ||
                              el?.market_place?.toLowerCase()?.includes(keyword)
                      );
                  })
            : [];

    return (
        <SmartIntel>
            <PageHeader browserTitle="Computers | Smartcomply" />
            {switch_on && intel?.length ? (
                <div className="mb-10 transition ease-in-out duration-500">
                    <div className="bg-[#E5F1FF] rounded-full px-3 py-2 flex justify-between items-center">
                        <div className="flex items-start !h-[24px] gap-2 w-[85%]">
                            <img src={SmartIntelLogo} alt="SmartIntelLogo" className="xl:block md:hidden" />
                            <p className="bg-[#F43F5E] text-white text-[13px] rounded px-1 py-0 w-fit xl:block md:hidden">
                                {intel?.length}
                            </p>
                            <Carousel
                                slides={intel}
                                handleOpen={handleOpen}
                                type="People & Computers"
                                data={all_smart_intel?.['People & Computers']}
                                containerStyles="page-slide !h-[24px]"
                            />
                        </div>
                        <img src={ArrowRight} alt="ArrowRight" />
                    </div>
                </div>
            ) : null}
            <AppTable
                columns={columns}
                data={pageData}
                title={t('computerTitle')}
                loading={loading}
                dataLength={data?.length}
                noPerPage={NO_PER_PAGE}
                page={page}
                onPageChange={handlePageChange}
                search={true}
                onSearch={handleSearch}
            />
        </SmartIntel>
    );
};

const mapStateToProps = (state) => {
    return {
        all_computers: state?.merchantReducers?.all_computers,
    };
};
export default connect(mapStateToProps, { GetAllComputers })(Computers);
