import React, { useMemo, useEffect, useState, useContext } from 'react';

// core components
import LoadingState from 'components/new_components/LoadingState';
import EmptyState from 'components/new_components/EmptyState';

// redux
import { connect } from 'react-redux';
import { GetAllExemptedForms } from 'store/actions/complianceActions';
import { toast } from 'react-toastify';

//translations
import { useTranslation } from 'react-i18next';
import { SelectedMerchantContext } from 'store/context/SelectedMerchantContextProvider';
import Card from './Card';
import ExceptionModal from '../ExceptionModal';
import RevertModal from '../RevertModal';

const Index = (props) => {
    const { GetAllExemptedForms, exempted_forms, selectedSubgroup, onExempted, all_data } = props;

    // state
    const [loading, setLoading] = useState({ content: false });
    const [openModal, setOpenModal] = useState(false);
    const [name, setName] = useState('');
    const [openRevertModal, setOpenRevertModal] = useState(false);
    const [id, setid] = useState('');

    const handleOpenRevert = (id) => {
        setid(id);
        setOpenRevertModal(true);
    };

    const handleCloseRevert = () => {
        setOpenRevertModal(false);
    };

    //translation
    const { t } = useTranslation('compliance');

    const handleOpenModal = (detail) => {
        setName(detail);
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    const { activeMerchant } = useContext(SelectedMerchantContext);
    // logic functions
    //To filter controls that has exempted form
    const getFilteredControls = (items) => {
        const filteredControls = all_data?.filter((control) => {
            const control_id = control?.id;
            const filtered_numbers = control?.sub_compliance_mgt?.map((item) => item?.number);

            const controlHasExemption = items?.some((exemption) => {
                const exemptionControlID = exemption?.form_detail?.compliance_mgt;
                return (
                    exemptionControlID === control_id &&
                    filtered_numbers?.includes(exemption?.form_detail?.compliance_mgt_sub_no)
                );
            });
            return controlHasExemption;
        });
        const controlsWithFilteredSubcontrols = filteredControls?.map((control) => {
            const filteredSubcontrols = control.sub_compliance_mgt.filter((sub_control) => {
                const subcontrolTitle = sub_control?.number;
                const subcontrolHasExemption = items?.some((exemption) => {
                    const exemptionSubcontrol = exemption?.form_detail?.compliance_mgt_sub_no;
                    return exemptionSubcontrol === subcontrolTitle;
                });
                return subcontrolHasExemption;
            });

            control.sub_lifecycle = filteredSubcontrols;
            return control;
        });
        const formattedControls = controlsWithFilteredSubcontrols.map((control) => {
            return {
                id: control?.id,
                title: control?.title,
                description: control?.service_category,
                subgroups: control?.sub_compliance_mgt?.map((subcontrol) => ({
                    label: `${t('sub')} ${subcontrol?.title}`,
                    id: subcontrol?.number,
                })),
            };
        });
        return formattedControls;
    };

    const getAllExemptedQuestions = async () => {
        setLoading((current) => ({ ...current, content: true }));
        const res = await GetAllExemptedForms('iso37301', activeMerchant);
        setLoading((current) => ({ ...current, content: false }));
        if (!res?.success) {
            toast.error("Something went wrong! Couldn't fetch exceptions.");
        }
    };

    // memos
    const controlsWithExceptions = useMemo(() => {
        return getFilteredControls(exempted_forms || []);
    }, [exempted_forms]);
    useEffect(() => {
        getAllExemptedQuestions();
    }, [activeMerchant]);
    return (
        <div>
            {!loading?.content ? (
                !controlsWithExceptions?.length ? (
                    <EmptyState description={t('noException')} />
                ) : (
                    <div className="grid xl:grid-cols-4 md:grid-cols-3 mt-3 gap-5">
                        {controlsWithExceptions?.map((group) => (
                            <div key={group?.id} className="bg-white border border-[#F1F5F9] rounded my-1 text-sm p-4">
                                <Card
                                    active={selectedSubgroup}
                                    group={group}
                                    id={group?.id}
                                    handleOpenModal={handleOpenModal}
                                    exempted_forms={exempted_forms}
                                />
                            </div>
                        ))}
                    </div>
                )
            ) : (
                <LoadingState />
            )}
            <ExceptionModal
                open={openModal}
                handleClose={handleCloseModal}
                resource={'form'}
                onExempted={onExempted}
                name={name}
                handleOpenRevert={handleOpenRevert}
            />
            <RevertModal
                open={openRevertModal}
                handleClose={handleCloseRevert}
                id={id}
                onExempted={onExempted}
                resource={'form'}
            />
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        exempted_forms: state?.complianceReducers?.all_exempted_forms,
    };
};

export default connect(mapStateToProps, {
    GetAllExemptedForms,
})(Index);
