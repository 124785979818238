import React from 'react';
import Carousel from 'components/new_components/carousel/Carousel';
import SmartIntelLogo from 'assets/img/Smartcomply Intel fav 1.svg';
import ArrowRight from 'assets/img/arrow-up-right.svg';
import { useSelector } from 'react-redux';
import ErrorZap from 'assets/img/lucide_zap_error.png';
import WarningZap from 'assets/img/lucide_zap_warning.png';
import CheckSuccess from 'assets/img/check_success.svg';
import Calendar from 'assets/img/calendar.png';
import moment from 'moment';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useHistory } from 'react-router-dom';
import InfoCircle from 'assets/img/info-circle.png';
import PassCheck from 'assets/img/pass_check.png';
import Discoveries from 'assets/img/discoveries.svg';

const SecurityScans = (props) => {
    const { switch_on, handleOpen } = props;
    const { dashboardResult } = useSelector((state) => state?.dashboardReducers);
    const { all_smart_intel } = useSelector((state) => state?.generalReducers);
    const merchant_info = useSelector((state) => state?.merchantReducers?.merchant_info);

    const keywords = merchant_info?.intel_keywords?.['Servers'];
    const router = useHistory();
    const gotoPage = (link) => {
        router.push(link);
    };
    const intel =
        switch_on && all_smart_intel?.['Servers']
            ? Object?.values(all_smart_intel?.['Servers'])
                  ?.map((option) => option)
                  ?.flat()
                  ?.filter((el) => {
                      return keywords?.some(
                          (keyword) =>
                              el?.title?.toLowerCase()?.includes(keyword) ||
                              el?.market_place?.toLowerCase()?.includes(keyword)
                      );
                  })
            : [];

    return (
        <div className="bg-white border-1 border-[#F1F5F9] rounded-md p-3">
            <h4 className="text-[#2B3674] text-lg font-semibold">Security Scans</h4>

            {switch_on && intel?.length ? (
                <div className=" my-[1.5rem] transition ease-in-out duration-500">
                    <div className="bg-[#E5F1FF] rounded-full px-3 py-2 flex justify-between items-center w-full 2xl:h-[50px] xl:h-[70px] md:[100px]">
                        <div className="flex items-center gap-2 w-[85%]">
                            <img src={SmartIntelLogo} alt="SmartIntelLogo" className="xl:block md:hidden" />
                            <p className="bg-[#F43F5E] text-white text-[13px] rounded px-1 py-0.5 w-fit xl:block md:hidden">
                                {intel?.length}
                            </p>
                            <Carousel
                                slides={intel}
                                handleOpen={handleOpen}
                                type="Servers"
                                data={all_smart_intel?.['Servers']}
                            />
                        </div>
                        <img src={ArrowRight} alt="ArrowRight" />
                    </div>
                </div>
            ) : null}
            <div className={`${switch_on ? 'mt-[4rem]' : 'mt-[2rem]'}`}>
                <h4 className="text-[#395BA9] text-sm font-bold">Penetration Test</h4>
                <div className="flex justify-between items-center mt-[0.5rem] gap-3">
                    <div>
                        <div className="flex items-center 2xl:gap-3 md:gap-1.5">
                            <div className="bg-[#FFF1F2] rounded px-2 py-1 flex items-center gap-1">
                                <img src={ErrorZap} alt="ErrorZap" />
                                <p className="text-[#F43F5E] text-xs font-medium">
                                    {dashboardResult?.pentest?.high} Critical
                                </p>
                            </div>
                            <div className="bg-[#FFFBEB] rounded px-2 py-1 flex items-center gap-1">
                                <img src={WarningZap} alt="WarningZap" />
                                <p className="text-[#D97706] text-xs font-medium">
                                    {dashboardResult?.pentest?.medium} High
                                </p>
                            </div>
                            <div className="bg-[#ECFDF5] rounded px-2 py-1 flex items-center gap-1">
                                <img src={CheckSuccess} alt="CheckSuccess" />
                                <p className="text-[#10B981] text-xs font-medium">
                                    {dashboardResult?.pentest?.low} Low
                                </p>
                            </div>
                        </div>
                        <div className="flex items-center gap-2 mt-2">
                            <img src={Calendar} alt="Calendar" />
                            <p className="text-[#94A3B8] text-xs font-medium">
                                Next text:{' '}
                                <span className="text-[#475566] ">
                                    {moment(dashboardResult?.pentest?.next_scan_date).format('MMMM DD, YYYY')}
                                </span>
                            </p>
                        </div>
                    </div>
                    <div
                        className="bg-[#F1F5F9] rounded-full p-1 cursor-pointer"
                        onClick={() => gotoPage('/merchant/scans/pentest')}
                    >
                        <KeyboardArrowRightIcon sx={{ color: '#395BA9' }} />
                    </div>
                </div>
            </div>
            <div className={`${switch_on ? 'mt-[2rem]' : 'mt-[2rem]'}`}>
                <h4 className="text-[#395BA9] text-sm font-bold">PCI Scan</h4>
                <div className="flex justify-between items-center mt-[0.5rem]">
                    <div>
                        <div className="flex items-center gap-3">
                            <div className="bg-[#FFF1F2] rounded px-2 py-1 flex items-center gap-1">
                                <img src={InfoCircle} alt="InfoCircle" />
                                <p className="text-[#F43F5E] text-xs font-medium">
                                    {dashboardResult?.asv?.failed} Failed
                                </p>
                            </div>
                            <div className="bg-[#ECFDF5] rounded px-2 py-1 flex items-center gap-1">
                                <img src={PassCheck} alt="PassCheck" />
                                <p className="text-[#10B981] text-xs font-medium">
                                    {dashboardResult?.asv?.passed} Passed
                                </p>
                            </div>
                        </div>
                        <div className="flex items-center gap-2 mt-2">
                            <img src={Calendar} alt="Calendar" />
                            <p className=" text-[#94A3B8] text-xs font-medium">
                                Next text:{' '}
                                <span className="text-[#475566]">
                                    {moment(dashboardResult?.asv?.next_scan_date).format('MMMM DD, YYYY')}
                                </span>
                            </p>
                        </div>
                    </div>
                    <div
                        className="bg-[#F1F5F9] rounded-full p-1 cursor-pointer"
                        onClick={() => gotoPage('/merchant/scans/pciscans')}
                    >
                        <KeyboardArrowRightIcon sx={{ color: '#395BA9' }} />
                    </div>
                </div>
            </div>
            {dashboardResult?.infrastructure_count > 0 && (
                <div className={`${switch_on ? 'mt-[2rem]' : 'mt-[2rem]'}`}>
                    <h4 className="text-[#395BA9] text-sm font-bold">Infrastructure Scan</h4>
                    <div className="flex justify-between items-center">
                        <div className="flex items-center gap-2">
                            <img src={Discoveries} alt="Discoveries" />
                            <p className="text-[#475569] text-xs font-bold">{dashboardResult?.infrastructure_count}</p>
                            <p className="text-[#475569] text-xs font-medium">Discoveries</p>
                        </div>
                        <div
                            className="bg-[#F1F5F9] rounded-full p-1 cursor-pointer"
                            onClick={() => gotoPage('/merchant/scans/automated-scans')}
                        >
                            <KeyboardArrowRightIcon sx={{ color: '#395BA9' }} />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};
export default SecurityScans;
