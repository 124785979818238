import React, { useEffect, useState } from 'react';
import { Row, Col, Tabs } from 'antd';

// core component
import BreadCrumb from 'components/Breadcrumb';

// icons
import { FileOutlined, FormOutlined } from '@ant-design/icons';
import ISO37301DocumentsView from './IS037301DocumentView';
import ISO37301EvidencesView from './ISO37301EvidenceView';
import ISO37301FormsView from './ISO37301FormView';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { GetAllDocumentsByAltTag } from 'store/actions/adminActions';
import { GetDocumentsBySubComplianceManagement } from 'store/actions/adminActions';

// sub compliance
const { TabPane } = Tabs;

const FormsAndDocumentsView = (props) => {
    const { location } = props;
    const dispatch = useDispatch();
    const [loading, setLoading] = useState({ content: false });
    const getDocumentsBySubcontrol = async () => {
        setLoading({ ...loading, content: true });
        const res = await dispatch(GetDocumentsBySubComplianceManagement(location?.state?.subcontrol?.number));
        setLoading({ ...loading, content: false });
        if (!res?.success) {
            toast?.error(res?.message);
        }
    };

    const getAllAdminDocuments = async () => {
        const res = await dispatch(GetAllDocumentsByAltTag('iso37301'));
        if (!res?.success) {
            toast.error(res?.message);
        }
    };

    // useEffect
    useEffect(() => {
        getDocumentsBySubcontrol();
        getAllAdminDocuments();
    }, []);
    // all_admin_documents_by_control
    return (
        <div>
            <Row justify="space-between">
                <Col>
                    <BreadCrumb
                        views={[
                            { label: 'ISO37301', link: '/iso37301' },
                            {
                                label: location.state?.title,
                                link: '/iso37301/submanagement',
                                state: {
                                    management_id: location?.state?.subcontrol?.parent_compliance,
                                },
                            },
                            { label: `QS${location?.state?.subcontrol?.number}` },
                        ]}
                    />
                </Col>
            </Row>
            <section className="my-3">
                <Tabs defaultActiveKey="1">
                    <TabPane
                        tab={
                            <span>
                                <FileOutlined />
                                Documents
                            </span>
                        }
                        key="1"
                    >
                        <ISO37301DocumentsView subcontrol={location?.state?.subcontrol} loading={loading} />
                    </TabPane>
                    <TabPane
                        tab={
                            <span>
                                <FormOutlined />
                                Audit Questions
                            </span>
                        }
                        key="2"
                    >
                        <ISO37301FormsView subcontrol={location?.state?.subcontrol} />
                    </TabPane>
                    <TabPane
                        tab={
                            <span>
                                <FileOutlined />
                                Evidences
                            </span>
                        }
                        key="3"
                    >
                        <ISO37301EvidencesView subcontrol={location?.state?.subcontrol} />
                    </TabPane>
                </Tabs>
            </section>
        </div>
    );
};

export default FormsAndDocumentsView;
