import React, { useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
//components
import {
    Box,
    Typography,
    Stack,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
} from '@mui/material';
import { useTheme } from '@mui/styles';
import AppCustomSwitch from 'components/new_components/AppCustomSwitch';

//redux
import { connect, useDispatch, useSelector } from 'react-redux';
import { GetCompanyDetails, UpdateCompanyDetails } from 'store/actions/merchantActions';
//translation

import { ReactComponent as BulbIcon } from 'assets/img/bulb (2).svg';
import { ReactComponent as QuestionMarkIcon } from 'assets/img/question-mark.svg';
import { ReactComponent as CyberThreatIcon } from 'assets/img/cyberthreat.svg';
import { ReactComponent as FileCheckIcon } from 'assets/img/file-check.svg';
import { GetDefaultKeywords } from 'store/actions/merchantActions';

import { Loader2 } from 'lucide-react';
import CustomTooltip from 'components/new_components/CustomTooltip';
import CloseIcon from 'assets/img/smartIntel/Icon Button.svg';
import Light from 'assets/img/Light.svg';
import { CloseOutlined } from '@ant-design/icons';

import AppViewModal from 'components/new_components/AppViewModal';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBack';
import { removeSpaces } from 'utils';
import CustomSwitch from 'components/new_components/CustomSwitch';
import { getType } from 'utils';

const Compliance = (props) => {
    const theme = useTheme();
    const [autoFill, setToggleAutoFill] = useState(false);
    const [openFeed, setOpenFeed] = useState(false);
    const [openDrawer, setOpenDrawer] = useState(false);

    //accessing values from the redux store
    const user_type = useSelector((state) => state?.authReducers?.user_type);
    const role = useSelector((state) => state?.generalReducers?.user_info?.role);

    const { GetCompanyDetails, UpdateCompanyDetails, merchant_info } = props;
    const handleToggleAutoFill = async () => {
        setToggleAutoFill(!autoFill);
        const res = await UpdateCompanyDetails({ compliance_ai: !autoFill });
        if (res?.success) {
            // toast.success(`Compliance AI turned ${!autoFill === true ? 'on' : 'off'}`);
        } else {
            toast.error(res?.message);
            setToggleAutoFill(autoFill);
        }
    };

    useEffect(() => {
        const getCompanyDetails = async () => {
            const res = await GetCompanyDetails();
            if (!res?.success) {
                toast.success(res?.message);
            }
        };
        getCompanyDetails();
    }, []);

    useEffect(() => {
        if (merchant_info?.compliance_ai) {
            setToggleAutoFill(true);
        } else {
            setToggleAutoFill(false);
        }
    }, [merchant_info?.compliance_ai]);
    return (
        <Box sx={{ p: 3, background: 'white', my: 3, width: '80%', mx: 'auto' }}>
            <Typography sx={{ color: '#202d66', fontSize: 20, mb: 3, fontWeight: 700 }}>Advanced Settings</Typography>
            <Stack
                sx={{
                    border: '1px solid #F0F0FA',
                    background: '#F8FAFC',
                    px: 2.5,
                    py: 1,
                    flexDirection: 'row',
                    justifyContent: 'start',
                    gap: 2,
                    alignItems: 'start',
                }}
            >
                <BulbIcon className="flex-shrink-0 w-7 h-7" />
                <Typography sx={{ color: theme.palette.neutral[500], fontSize: 15 }}>
                    Explore advanced settings for customized control. Adjust configurations to tailor the app to your
                    specific needs, but proceed with caution and ensure you understand the implications of each change.
                </Typography>
            </Stack>

            <Box sx={{ my: 4 }}>
                <Stack
                    width="100%"
                    flexDirection="row"
                    justifyContent="space-between"
                    gap={2.5}
                    alignItems="center"
                    borderBottom="1px solid #F1F5F9"
                    py={3}
                    px={2.5}
                >
                    <div className="flex gap-8 justify-start items-center flex-1">
                        <QuestionMarkIcon className="flex-shrink-0 w-14 h-14" />
                        <Box maxWidth="70%">
                            <div className="relative w-fit mb-2">
                                <h3 className="text-[#202566] font-medium text-lg">Autofill audit Questions </h3>
                            </div>

                            <p className="text-sm font-normal text-[#5E5E62]">
                                Toggle audit questions based on your needs. Turning them on ensures thorough compliance
                                checks, while switching them off streamlines your workflow.
                            </p>
                        </Box>
                    </div>

                    {(user_type !== 'auditor' || role !== 'auditor') && (
                        <AppCustomSwitch checked={autoFill} onChange={handleToggleAutoFill} />
                    )}
                </Stack>

                <Stack
                    width="100%"
                    flexDirection="row"
                    justifyContent="space-between"
                    gap={2.5}
                    alignItems="center"
                    borderBottom="1px solid #F1F5F9"
                    py={3}
                    px={2.5}
                >
                    <div className="flex gap-8 justify-start items-center flex-1">
                        <FileCheckIcon className="flex-shrink-0 w-14 h-14" />
                        <Box maxWidth="70%">
                            <div className="relative w-fit mb-2">
                                <h3 className="text-[#202566] font-medium text-lg">Audit Standards </h3>
                            </div>

                            <p className="text-sm font-normal text-[#5E5E62]">
                                Select your desired audit standards to tailor compliance checks. Choose the standards
                                relevant to your industry or regulatory requirements for accurate assessments.
                            </p>
                        </Box>
                    </div>

                    <Button
                        size="small"
                        sx={{ border: '1px solid #E2E8F0', color: '#334155', textTransform: 'capitalize', px: 2 }}
                        onClick={() => setOpenDrawer(true)}
                    >
                        View
                    </Button>
                </Stack>

                <Stack
                    width="100%"
                    flexDirection="row"
                    justifyContent="space-between"
                    gap={2.5}
                    alignItems="center"
                    py={3}
                    px={2.5}
                >
                    <div className="flex gap-8 justify-start items-center flex-1">
                        <CyberThreatIcon className="flex-shrink-0 w-14 h-14" />
                        <Box maxWidth="70%">
                            <div className="relative w-fit mb-2">
                                <h3 className="text-[#202566] font-medium text-lg">Cyber Threat feed settings</h3>
                            </div>

                            <p className="text-sm font-normal text-[#5E5E62]">
                                Choose which feeds you would like to be receive on your cyber threat intelligence.
                            </p>
                        </Box>
                    </div>

                    <Button
                        size="small"
                        sx={{ border: '1px solid #E2E8F0', color: '#334155', textTransform: 'capitalize', px: 2 }}
                        onClick={() => setOpenFeed(true)}
                    >
                        Customize
                    </Button>
                </Stack>
            </Box>
            <FeedSettings open={openFeed} onClose={() => setOpenFeed(false)} />
            <AuditStandards open={openDrawer} onClose={() => setOpenDrawer(false)} />
        </Box>
    );
};

const FeedSettings = ({ open, onClose }) => {
    const [loading, setLoading] = useState(false);
    const [compliance_keywords, setComplianceKeywords] = useState([]);
    const [people_computer_keywords, setPeopleComputerKeywords] = useState([]);
    const [risk_distribution_keywords, setRiskDistributionKeywords] = useState([]);
    const [servers_keywords, setServersKeywords] = useState([]);
    const [third_party_keywords, setThirdPartyKeywords] = useState([]);
    const dispatch = useDispatch();
    const [default_keywords, setDefaultKeywords] = useState(null);

    const merchant_info = useSelector((state) => state?.merchantReducers?.merchant_info);
    const handleUpdateKeywords = async () => {
        setLoading(true);
        const body = {
            intel_keywords: {
                Compliance: compliance_keywords,
                'People & Computers': people_computer_keywords,
                'Risk Distribution': risk_distribution_keywords,
                Servers: servers_keywords,
                'Third-Parties': third_party_keywords,
            },
        };
        const res = await dispatch(UpdateCompanyDetails(body));
        setLoading(false);
        if (res?.success) {
            dispatch({ type: 'MERCHANT_INFO', payload: res.data });
            toast.success('Feed keywords updated Successfully');
            onClose();
        } else {
            toast.error(res?.message);
        }
    };

    const getDefaultKeywords = async () => {
        setLoading(true);
        const res = await dispatch(GetDefaultKeywords());
        setLoading(false);
        if (res?.success) {
            setDefaultKeywords(res?.data);
        }
    };

    useEffect(() => {
        getDefaultKeywords();
    }, []);

    useEffect(() => {
        if (merchant_info?.intel_keywords) {
            setComplianceKeywords(merchant_info?.intel_keywords?.['Compliance'] || []);
            setThirdPartyKeywords(merchant_info?.intel_keywords?.['Third-Parties'] || []);
            setPeopleComputerKeywords(merchant_info?.intel_keywords?.['People & Computers'] || []);
            setRiskDistributionKeywords(merchant_info?.intel_keywords?.['Risk Distribution'] || []);
            setServersKeywords(merchant_info?.intel_keywords?.['Servers'] || []);
        }
    }, [merchant_info]);

    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth={true}>
            <DialogTitle className="flex justify-between items-center">
                <p className="text-[#202D66] text-xl font-semibold">Feed Settings</p>
                <img src={CloseIcon} alt="CloseIcon" className="cursor-pointer" onClick={onClose} />
            </DialogTitle>
            <DialogContent className="my-[0.5rem]">
                <DialogContentText>
                    <Stack
                        flexDirection="row"
                        sx={{
                            border: `1px solid #F0F0FA`,
                            backgroundColor: '#F8FAFC',
                            borderRadius: '4px',
                            p: '5px',
                        }}
                    >
                        <img src={Light} alt="file question" />
                        <p className="tetx-[#64748B] text-[13px] font-normal mt-2 ml-2">
                            Customize your intelligence feeds with specific keywords to ensure they are tailored to
                            deliver only relevant, organization-specific insights.
                        </p>
                    </Stack>
                    <div className="my-[1rem]">
                        <div className="flex items-center gap-2">
                            <p className="text-[#475569] text-base font-medium">Feeds keywords</p>
                            <CustomTooltip
                                title="Keywords could include;  Organizations Specific Keywords, Industry Specific Keywords, Geographic keywords, Technology keywords etc."
                                placement="top"
                            >
                                <img
                                    src="/img/risk-assessment/help-circle.svg"
                                    alt="Help Icon"
                                    className="object-contain"
                                />
                            </CustomTooltip>
                        </div>
                        <div className="my-3">
                            <p className="text-[#475569] text-base font-medium">Compliance</p>
                            <Keywords
                                keywords={compliance_keywords}
                                setKeywords={setComplianceKeywords}
                                id="Compliance"
                                default_keywords={default_keywords}
                            />
                            <p className="text-[#64748B] text-xs font-normal mt-2">
                                Use commas to separate each keyword. Keywords are not case sensitive.
                            </p>
                        </div>

                        <div className="my-3">
                            <p className="text-[#475569] text-base font-medium">People & Computers</p>
                            <Keywords
                                keywords={people_computer_keywords}
                                setKeywords={setPeopleComputerKeywords}
                                id="People & Computers"
                                default_keywords={default_keywords}
                            />
                            <p className="text-[#64748B] text-xs font-normal mt-2">
                                Use commas to separate each keyword. Keywords are not case sensitive.
                            </p>
                        </div>

                        <div className="my-3">
                            <p className="text-[#475569] text-base font-medium">Risk Distribution</p>
                            <Keywords
                                keywords={risk_distribution_keywords}
                                setKeywords={setRiskDistributionKeywords}
                                id="Risk Distribution"
                                default_keywords={default_keywords}
                            />
                            <p className="text-[#64748B] text-xs font-normal mt-2">
                                Use commas to separate each keyword. Keywords are not case sensitive.
                            </p>
                        </div>

                        <div className="my-3">
                            <p className="text-[#475569] text-base font-medium">Servers</p>
                            <Keywords
                                keywords={servers_keywords}
                                setKeywords={setServersKeywords}
                                id="Servers"
                                default_keywords={default_keywords}
                            />
                            <p className="text-[#64748B] text-xs font-normal mt-2">
                                Use commas to separate each keyword. Keywords are not case sensitive.
                            </p>
                        </div>

                        <div className="my-3">
                            <p className="text-[#475569] text-base font-medium">Third-Parties Threat</p>
                            <Keywords
                                keywords={third_party_keywords}
                                setKeywords={setThirdPartyKeywords}
                                id="Third-Parties"
                                default_keywords={default_keywords}
                            />
                            <p className="text-[#64748B] text-xs font-normal mt-2">
                                Use commas to separate each keyword. Keywords are not case sensitive.
                            </p>
                        </div>
                    </div>
                </DialogContentText>
            </DialogContent>
            <DialogActions className="bg-[#F8FAFC] flex justify-right items-center gap-2 py-4 px-3">
                <Button
                    variant="outlined"
                    sx={{
                        border: '1px solid #E2E8F0',
                        textTransform: 'inherit',
                        color: '#334155',
                        backgroundColor: '#FFFFFF',
                    }}
                    onClick={onClose}
                >
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    sx={{
                        textTransform: 'inherit',
                        fontWeight: 600,
                        px: 2,
                        py: 1,
                        borderRadius: 0.5,
                        display: 'flex',
                        alignItems: 'center',
                        gap: 2,
                    }}
                    onClick={handleUpdateKeywords}
                >
                    {loading && <Loader2 className="mr-1 h-4 w-4 animate-spin" />} Update Settings
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const Keywords = ({ keywords, setKeywords, id, default_keywords }) => {
    const [open, setOpen] = useState(false);
    const [query, setQuery] = useState('');

    const filteredKeywords = useMemo(() => {
        const data = default_keywords?.[id] || [];

        return data?.filter(
            (el) =>
                el?.toLowerCase()?.includes(query?.toLowerCase()) &&
                !keywords?.some((keyword) => keyword?.toLowerCase() === el?.toLowerCase())
        );
    }, [query, keywords, id, default_keywords]);

    return (
        <div className="relative group mt-[0.5rem]">
            <div className="w-full relative flex flex-wrap gap-2 !border !border-[#E2E8F0] !rounded-md !px-2 !py-[10px] focus-within:ring-2 focus-within:ring-[#395BA9] focus-within:ring-opacity-75">
                {keywords?.map((keyword, index) => (
                    <button
                        key={index}
                        className="flex items-center gap-2 !border !border-[#F1F5F9] bg-[#F1F5F9] !rounded !py-0 !px-2 hover:brightness-90 transition-all h-6"
                        onClick={(event) => {
                            event.stopPropagation();
                            setKeywords(keywords?.filter((item) => item !== keyword));
                        }}
                    >
                        <p className="font-normal capitalize text-[13px] text-[#64748B]">{keyword}</p>

                        <img
                            src="/img/risk-assessment/x-close-small.svg"
                            alt="Delete Icon"
                            className="object-contain"
                        />
                    </button>
                ))}
                <input
                    placeholder="Add feed keywords"
                    onFocus={(event) => {
                        if (event.target.innerText === 'Add feed keywords') {
                            event.target.innerText = '';
                        }

                        if (!open) setOpen(true);

                        event.target.style.color = 'hsl(215,20%,40%)';
                    }}
                    onChange={(event) => {
                        if (event?.target?.value?.includes(',') && event.target.value.length > 2) {
                            setKeywords([...keywords, ...event.target.value.split(',')].filter(Boolean));
                            event.target.value = '';
                        }
                        setQuery(event.target.value);
                    }}
                    className="border-none text-[#94A3B8] outline-none w-full h-8"
                />
                {open ? (
                    <CloseOutlined
                        onClick={() => setOpen(false)}
                        className="text-[#000] text-lg absolute right-0 bottom-3 z-[2000] cursor-pointer"
                    />
                ) : null}
                <div className="relative w-full">
                    {open ? (
                        <ul className="absolute w-full bg-[#ffffff] overflow-y-auto h-32 z-[1000] py-2 shadow-md">
                            {filteredKeywords?.length ? (
                                filteredKeywords?.map((el, key) => (
                                    <li
                                        key={key}
                                        onClick={() => {
                                            setKeywords([...keywords, el].filter(Boolean));
                                        }}
                                        className="cursor-pointer font-normal capitalize text-[14px] p-2 px-3 text-[#64748B]"
                                    >
                                        {el}
                                    </li>
                                ))
                            ) : (
                                <p className="font-semibold text-[15px] text-center p-2 text-[#64748B]">
                                    No keywords available
                                </p>
                            )}
                        </ul>
                    ) : null}
                </div>
            </div>
        </div>
    );
};

const AuditStandards = ({ open, onClose }) => {
    const dispatch = useDispatch();
    const merchant_info = useSelector((state) => state?.merchantReducers?.merchant_info);
    const allstandards = merchant_info?.standards?.split(',') || [];
    const [loading, setLoading] = useState(false);
    const [updatedStandards, setUpdatedStandards] = useState([]);

    const role = getType('role');

    // memos
    // user standards
    const standards = useMemo(() => {
        const free_plan_standards = ['PCIDSS'];

        const selected_standards =
            merchant_info?.merchant_plan?.tag === 'demo' || merchant_info?.merchant_plan?.tag === '3_days'
                ? free_plan_standards
                : removeSpaces(merchant_info?.paid_standards)?.split(',');
        return selected_standards
            ?.filter((val) => val !== '')
            ?.map((standard) => {
                return {
                    name: standard?.toUpperCase(),
                    value: standard?.toLowerCase(),
                    label: standard,
                };
            });
    }, [merchant_info]);

    useEffect(() => {
        if (!standards?.length) return;

        // const userStandards = standards?.filter((el) => allstandards.includes(el.value))?.map((el) => el.value);
        // const userStandards = standards?.filter((el) => allstandards.includes(el.value))?.map((el) => el.value);

        setUpdatedStandards(allstandards);
    }, [standards]);

    const handleSubmit = async () => {
        setLoading(true);

        const res = await dispatch(
            UpdateCompanyDetails({
                standards: updatedStandards?.join(','),
            })
        );
        setLoading(false);
        if (res?.success) {
            toast.success('Audit standards updated successfully.');
            onClose();
        } else {
            toast.error(res?.message);
        }
    };

    const updateStandard = (standard) => {
        const newStandards = updatedStandards.filter((el) => el?.toLowerCase() !== standard?.toLowerCase());
        setUpdatedStandards(newStandards);
    };

    const getLabel = (standard) => {
        if (!standard) return '';

        switch (standard?.toLowerCase()) {
            case 'pcidss':
                return 'Payment Card Industry Data Security Standard';
            case 'pcidss4.0':
                return 'Payment Card Industry Data Security Standard version 4.0';
            case 'ndpr':
                return 'Nigerian Data Protection Regulations';
            case 'iso27001':
                return 'International Organization for Standardization 27001';
            case 'iso27001-2022':
                return 'International Organization for Standardization 27001(2022)';
            case 'cii':
                return 'Critical Information Infrastructure Directive';
            case 'soc2':
                return 'Service Organization Control 2';
            case 'gdpr':
                return 'General Data Protection Regulation';
            case 'kdpa':
                return 'Kenya Data Protection Act';
            default:
                return '';
        }
    };

    return (
        <AppViewModal
            sx={{ overflow: 'hidden' }}
            wrapperStyles={{ display: 'flex', flexDirection: 'column', height: '100vh' }}
            open={open}
            handleClose={onClose}
            width={520}
        >
            <Box sx={{ py: 1.5, height: '60px', border: '1px solid #F1F5F9' }}>
                <Box sx={{ display: 'flex', mx: 3, pb: 2, alignItems: 'center' }}>
                    <ArrowBackIosIcon
                        onClick={onClose}
                        sx={{ width: 20, height: 20, color: '#334155', cursor: 'pointer' }}
                    />
                    <Typography sx={{ fontSize: '17px', fontWeight: 600, color: '#202D66', ml: 2 }}>
                        Audit Standards
                    </Typography>
                </Box>
            </Box>
            <Box px={3} py={1} className="flex-1 overflow-y-auto">
                <Stack
                    sx={{
                        border: '1px solid #F0F0FA',
                        background: '#F8FAFC',
                        px: 2.5,
                        py: 1,
                        flexDirection: 'row',
                        justifyContent: 'start',
                        gap: 2,
                        alignItems: 'start',
                        my: 2,
                    }}
                >
                    <Typography sx={{ color: '#5E5E62', fontSize: 15 }}>
                        Select your desired audit standards you want to be displayed on your dashboard.
                    </Typography>
                </Stack>
                <div className="flex flex-col gap-5 w-full">
                    {standards?.map((standard) => (
                        <div key={standard.value} className="flex justify-between items-center gap-4">
                            <p className="capitalize text-[#5E5E62] text-sm font-semibold">
                                {standard.label}{' '}
                                {getLabel(standard.value) ? (
                                    <span className="font-normal"> ({getLabel(standard.value)})</span>
                                ) : null}
                            </p>
                            <CustomSwitch
                                checked={updatedStandards.includes(standard.value)}
                                onChange={() => {
                                    !updatedStandards.includes(standard.value)
                                        ? setUpdatedStandards((prev) => [...prev, standard.value])
                                        : updateStandard(standard.value);
                                }}
                                disabled={loading || role === 'user' || role === 'auditor'}
                            />
                        </div>
                    ))}
                </div>
            </Box>

            <div className="h-20 w-full px-6 bg-[#F8FAFC] flex justify-end items-center gap-4">
                <button
                    onClick={onClose}
                    className="px-3 py-2 text-[#334155] font-medium bg-white border border-[#E2E8F0] rounded-sm"
                >
                    Close
                </button>
                <button
                    disabled={loading}
                    onClick={handleSubmit}
                    className="px-3 py-2 bg-[#202D66] text-white font-medium rounded-sm"
                    type="submit"
                >
                    {loading ? <Loader2 className="h-5 w-5 animate-spin inline-flex mr-1.5" /> : null}
                    Save
                </button>
            </div>
        </AppViewModal>
    );
};

const mapStateToProps = (state) => {
    return {
        merchant_info: state?.merchantReducers?.merchant_info,
    };
};
export default connect(mapStateToProps, { GetCompanyDetails, UpdateCompanyDetails })(Compliance);
