import React from 'react';
import { Box, Stack, Typography, useTheme } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { CheckCircle } from '@mui/icons-material';
// import { AppSubmitButton } from 'components/new_components/forms';
import AppButton from 'components/new_components/AppButton';

//translation
import { useTranslation } from 'react-i18next';

const VendorSectionLayout = (props) => {
    const {
        children,
        questionnaireFormsCount,
        categories,
        page,
        setPage,
        showFooter,
        readonly,
        onNextClick,
        onPrevClick,
        // onPageChange,
        loading,
        responseCount,
        handleSubmit,
    } = props;

    const theme = useTheme();

    // const progressPercent = (page / questionnaireFormsCount) * 100;

    //translation
    const { t } = useTranslation('vendorManagement');
    return (
        <Box
            sx={{
                display: 'flex',
                padding: '0 0 0 10px',
                overflow: 'auto',
                height: '93vh',
            }}
        >
            <Stack sx={{ backgroundColor: '#F1F5F9', pt: 4, px: 1 }}>
                {categories?.map((category, index) => {
                    return (
                        <Box
                            sx={{
                                height: '58px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                padding: '0px 10px',
                                cursor: 'pointer',
                                backgroundColor: `${page === index + 1 ? '#DBEAFE' : '#F1F5F9'}`,
                            }}
                            onClick={() => (responseCount >= index ? setPage(index + 1) : null)}
                        >
                            <Typography
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    fontWeight: 500,
                                    fontSize: '14px',
                                    color: `${page === index + 1 ? '#395BA9' : '#64748B'}`,
                                }}
                            >
                                {category}
                            </Typography>
                            {responseCount > index ? (
                                <CheckCircle color={page === index + 1 ? 'success' : 'disabled'} />
                            ) : null}
                        </Box>
                    );
                })}
            </Stack>
            <Stack
                sx={{
                    mx: '6rem',
                    width: '73%',
                    height: '80vh',
                    mt: 4,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: '#FFFFFF',
                }}
            >
                <Box
                    sx={{
                        overflowY: 'auto',
                        '&::-webkit-scrollbar': {
                            width: '8px',
                        },
                        '&::-webkit-scrollbar-track': {
                            backgroundColor: 'transparent',
                        },
                        '&::-webkit-scrollbar-thumb': {
                            backgroundColor: theme.palette.primary[900] + '30',
                            borderRadius: '10px',
                        },
                    }}
                >
                    {children}
                </Box>
                {showFooter && (
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            padding: '1rem 2rem',
                        }}
                    >
                        <LoadingButton
                            variant="outlined"
                            // color="primary"
                            onClick={onPrevClick}
                            disabled={page <= 1}
                            textTransform="none"
                            sx={{
                                fontWeight: 600,
                                fontSize: '16px',
                                mr: '32px',
                                height: '38px',
                                px: 3,
                                textTransform: 'inherit',
                            }}
                        >
                            {t('vendorQuestionnaire.previous')}
                        </LoadingButton>
                        {/* <Pagination
                            color="primary"
                            shape="rounded"
                            count={questionnaireFormsCount}
                            page={page}
                            onChange={(_, page) => onPageChange(page)}
                            hideNextButton={true}
                            hidePrevButton={true}
                            size="large"
                            renderItem={(item) => {
                                const isOpened = responseCount >= item.page - 1;
                                return (
                                    <PaginationItem
                                        {...item}
                                        color={isOpened ? 'primary' : 'standard'}
                                        variant={item.selected ? 'text' : 'outlined'}
                                        sx={{ fontSize: '1rem', fontWeight: '600' }}
                                        disabled={!isOpened}
                                    />
                                );
                            }}
                        /> */}
                        {readonly ? (
                            <AppButton
                                variant="contained"
                                color="primary"
                                onClick={onNextClick}
                                disabled={page >= questionnaireFormsCount}
                                name={t('vendorQuestionnaire.next')}
                                sx={{ fontWeight: 600, fontSize: '16px', height: '38px', px: 3 }}
                            />
                        ) : (
                            // <AppButton
                            //     variant="contained"
                            //     color="primary"
                            //     loading={loading.form}
                            //     onClick={handleSubmit}
                            //     name={
                            //         page >= questionnaireFormsCount
                            //             ? t('vendorQuestionnaire.save1')
                            //             : t('vendorQuestionnaire.save2')
                            //     }
                            //     sx={{ fontWeight: 600, fontSize: '16px', height: '38px', px: 3, color: 'white' }}
                            // />
                            <LoadingButton
                                variant="contained"
                                color="primary"
                                type="button"
                                onClick={handleSubmit}
                                textTransform="none"
                                loading={loading.form}
                                loadingPosition="center"
                                sx={{
                                    fontWeight: 600,
                                    fontSize: '16px',
                                    height: '38px',
                                    px: 3,
                                    textTransform: 'inherit',
                                }}
                            >
                                {page >= questionnaireFormsCount
                                    ? t('vendorQuestionnaire.save1')
                                    : t('vendorQuestionnaire.save2')}
                            </LoadingButton>
                        )}
                    </Box>
                )}
            </Stack>
        </Box>
    );
};

export default VendorSectionLayout;
