import React, { createContext, useContext, useState } from 'react';

export const SmartIntelContext = createContext();

export const SmartIntelContextProvider = ({ children }) => {
    const [openDrawer, setOpenDrawer] = useState(false);
    const [currentData, setCurrentData] = useState(null);
    const [currentTag, setCurrentTag] = useState('');
    const [type, setType] = useState('');
    // const [, setCurrentThreat] = useState(null);

    const handleOpen = (data, type, tag) => {
        setCurrentTag(tag);
        setType(type);
        setCurrentData(data);
        setOpenDrawer(true);
    };

    const handleViewThreat = (option) => {
        // setCurrentThreat(option);
        // setViewThreat(true);
        console.log(option, 'view threat');
    };

    const handleClose = () => {
        setCurrentData(null);
        setOpenDrawer(false);
    };

    return (
        <SmartIntelContext.Provider
            value={{
                open: openDrawer,
                handleClose,
                currentData,
                type,
                currentTag,
                handleViewThreat,
                handleOpen,
            }}
        >
            {children}
        </SmartIntelContext.Provider>
    );
};

export const useSmartIntelContext = () => {
    return useContext(SmartIntelContext);
};

export default SmartIntelContextProvider;
