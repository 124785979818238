import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Drawer, Slide, Box, Stack, IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="right" ref={ref} {...props} />;
});

const AppViewModal = (props) => {
    const theme = useTheme();
    const {
        open,
        handleClose,
        children,
        title,
        width,
        icon,
        anchor,
        backIcon,
        closeicon,
        noClose,
        scans,
        sx,
        lightBorder,
        wrapperStyles,
    } = props;

    return (
        <Drawer
            open={open}
            anchor={anchor || 'right'}
            TransitionComponent={Transition}
            transitionDuration={1000}
            keepMounted
            onClose={handleClose}
            PaperProps={{
                sx: {
                    boxShadow: '0px 12px 24px rgba(0, 0, 0, 0.16)',
                    minWidth: 370,
                    width,
                    flex: 1,
                    overflow: 'auto',
                    zIndex: 9999,
                    ...sx,
                },
            }}
            scroll="paper"
        >
            <Box
                sx={{
                    borderBottom: scans
                        ? null
                        : lightBorder
                        ? '1px solid #F1F5F9'
                        : `1px solid ${theme.palette.primary[100]}`,
                }}
            >
                {title && (
                    <Stack
                        sx={{
                            fontSize: 16,
                            fontWeight: 700,
                            color: theme.palette.primary[900],
                            padding: 2,
                            width: noClose ? '55%' : '100%',
                            ...sx,
                        }}
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        {backIcon ? backIcon : null}
                        <Stack direction="row" justifyContent="flex-start" alignItems="center">
                            {icon && (
                                <span
                                    style={{
                                        background: '#fff',
                                        borderRadius: '50%',
                                        marginRight: '1rem',
                                        boxShadow:
                                            '1px 4px 8px rgba(0, 0, 0, 0.12), inset 0px -2px 4px rgba(14, 44, 102, 0.14)',
                                        width: '40px',
                                        height: '40px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                >
                                    {icon}
                                </span>
                            )}
                            {title}
                        </Stack>

                        {noClose ? null : (
                            <>
                                {closeicon ? (
                                    closeicon
                                ) : (
                                    <IconButton onClick={handleClose}>
                                        <Close sx={{ color: theme.palette.gray[500] }} />
                                    </IconButton>
                                )}
                            </>
                        )}
                    </Stack>
                )}
            </Box>
            <Box sx={{ flex: 1, ...wrapperStyles }}>{children}</Box>
        </Drawer>
    );
};

export default AppViewModal;
