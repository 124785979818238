import React, { useMemo } from 'react';
import { ArrowBackIosNew } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import PCIReport from './report/PCIReport';
import NDPRReport from './report/NDPRReport';
import SOCReport from './report/SOCReport';
import ISOReport from './report/ISOReport';
import BLOCKCHAINReport from './report/BLOCKCHAINReport';
import CONTINUITYReport from './report/CONTINUITYReport';
import PCIDSS4Point0Report from './report/PCIDSS4Point0Report';
import CIIReport from './report/CII';
import PCISSLCReport from './report/PCISSLCReport';

// core components
import AppViewModal from 'components/new_components/AppViewModal';
import { Box, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

//translations
import { useTranslation } from 'react-i18next';
import KDPAReport from './report/KDPAReport';
import GDPAReport from './report/GDPAReport';
import ISO9001Report from './report/iso9001';
import ISO37301Report from './report/iso37301';

const AllScanModal = (props) => {
    const theme = useTheme();
    const { open, handleClose, dashboardResult, standards } = props;

    //translation
    const { t } = useTranslation('overview');

    //constants
    const availableStandardCards = [
        {
            name: 'iso27001',
            component: (
                <div>
                    <ISOReport percentage={dashboardResult?.iso_percentage} />
                </div>
            ),
            pathname: '/merchant/compliance/iso27001',
        },
        {
            name: 'ndpr',
            component: (
                <div>
                    <NDPRReport percentage={dashboardResult?.ndpr_percentage} />
                </div>
            ),
            pathname: '/merchant/compliance/ndpr',
        },
        {
            name: 'soc2',
            component: (
                <div>
                    <SOCReport percentage={dashboardResult?.soc2_percentage} />
                </div>
            ),
            pathname: '/merchant/compliance/soc2',
        },
        {
            name: 'pcidss',
            component: (
                <div>
                    <PCIReport percentage={dashboardResult?.pci_percentage} />
                </div>
            ),
            pathname: '/merchant/compliance/pcidss',
        },
        {
            name: 'blockchain',
            component: (
                <div>
                    <BLOCKCHAINReport percentage={dashboardResult?.blockchain_percentage?.toFixed(2)} />
                </div>
            ),
            pathname: '/merchant/compliance/blockchain',
        },
        {
            name: 'iso22301',
            component: (
                <div>
                    <CONTINUITYReport percentage={dashboardResult?.continuity_percentage?.toFixed(2)} />
                </div>
            ),
            pathname: '/merchant/compliance/iso22301',
        },
        {
            name: 'pcidss4.0',
            component: (
                <div>
                    <PCIDSS4Point0Report percentage={dashboardResult?.card4_percentage} />
                </div>
            ),
            pathname: '/merchant/compliance/pcidss4.0',
        },
        {
            name: 'kdpa',
            component: (
                <div>
                    <KDPAReport percentage={dashboardResult?.kdpa_percentage?.toFixed(2)} />
                </div>
            ),
            pathname: '/merchant/compliance/kdpa',
        },
        {
            name: 'gdpa',
            component: (
                <div>
                    <GDPAReport percentage={dashboardResult?.gdpa_percentage?.toFixed(2)} />
                </div>
            ),
            pathname: '/merchant/compliance/gdpa',
        },
        {
            name: 'cii',
            component: (
                <div>
                    <CIIReport percentage={dashboardResult?.cii_percentage?.toFixed(2)} />
                </div>
            ),
            pathname: '/merchant/compliance/cii_directive',
        },
        {
            name: 'pcislc',
            component: (
                <div>
                    <PCISSLCReport percentage={dashboardResult?.sslc_percentage?.toFixed(2)} />
                </div>
            ),
            pathname: '/merchant/compliance/pcislc',
        },
        {
            name: 'iso9001',
            component: (
                <div>
                    <ISO9001Report percentage={dashboardResult?.iso9001_percentage?.toFixed(2)} />
                </div>
            ),
            pathname: '/merchant/compliance/iso9001',
        },
        {
            name: 'iso37301',
            component: (
                <div>
                    <ISO37301Report percentage={dashboardResult?.iso37301_percentage?.toFixed(2)} />
                </div>
            ),
            pathname: '/merchant/compliance/iso37301',
        },
    ];
    const availableCards = useMemo(() => {
        return availableStandardCards.filter((card) => {
            return standards?.includes(card.name);
        });
    }, [standards, availableStandardCards]);
    return (
        <AppViewModal
            scans
            width={1000}
            backIcon={
                <ArrowBackIosNew
                    sx={{
                        width: 35,
                        height: 35,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: '#DAE2FF',
                        color: theme.palette.gray[500],
                        p: 1,
                        mr: 2,
                        borderRadius: '50%',
                        cursor: 'pointer',
                    }}
                    onClick={handleClose}
                />
            }
            open={open}
            handleClose={handleClose}
            noClose
            title={
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography
                        variant="h2"
                        sx={{
                            color: '#0E2C66',
                            fontWeight: 800,
                            marginTop: 1,
                        }}
                    >
                        {t('allStandards')}
                    </Typography>
                </Box>
            }
            anchor="right"
        >
            <div className="m-4">
                <Typography
                    sx={{
                        color: '#0E2C66',
                        fontWeight: 600,
                        textDecoration: 'underline',
                        fontSize: '14px',
                    }}
                >
                    {t('activeStandards')}
                </Typography>
                <div className="grid lg:grid-cols-3 grid-cols-1 gap-5 my-3">
                    {availableCards?.map((card, index) => {
                        return (
                            <Link
                                style={{ textDecoration: 'none' }}
                                to={{
                                    pathname: card.pathname,
                                }}
                                key={index}
                            >
                                <React.Fragment key={card?.name}>{card?.component}</React.Fragment>
                            </Link>
                        );
                    })}
                </div>
            </div>
        </AppViewModal>
    );
};

export default AllScanModal;
