import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
// import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// core components
import { AppForm, AppFormInput, AppSubmitButton } from 'components/new_components/forms';
import AppAvatar from 'components/new_components/AppAvatar';
// images
import pfp from 'assets/img/pfp.png';
// redux
import { connect } from 'react-redux';
import { ChangePhoto, GetUserDetails, UpdateUserDetails } from 'store/actions/generalActions';

// validation
import { userValidation } from './utils/validation';

//translation
import { useTranslation } from 'react-i18next';
import { GetDownloadLink } from 'store/actions/generalActions';

const UserInfo = (props) => {
    const { ChangePhoto, GetUserDetails, UpdateUserDetails, user_info } = props;
    // const theme = useTheme();
    const imageInputRef = useRef();
    const [imageLink, setImageLink] = useState('');

    // state
    const [loading, setLoading] = useState({
        upload: false,
        submit: false,
        content: false,
    });

    //translation hook
    const { t } = useTranslation('accounts');

    // async functions
    const getUserDetails = async () => {
        // this will prevent a loading icon if the details are already fetched
        setLoading({ ...loading, content: !user_info });
        const res = await GetUserDetails();
        setLoading({ ...loading, content: false });
        if (!res?.success && !user_info) {
            toast.error(res?.message);
        }
    };
    const changePhoto = async (e) => {
        const formData = new FormData();
        const imageName = e.target.files[0];
        formData.append('picture', imageName);
        setLoading({ ...loading, upload: true });
        const res = await ChangePhoto(formData);
        setLoading({ ...loading, upload: false });
        imageInputRef.current.value = null;
        if (res.success) {
            toast.success('Your photo has been updated successfully');
        } else {
            toast.error(res?.message);
        }
    };
    const updateUserDetails = async (values) => {
        const { first_name, last_name, title } = values;
        setLoading({ ...loading, submit: true });
        const res = await UpdateUserDetails({ first_name, last_name, title });
        setLoading({ ...loading, submit: false });
        if (res?.success) {
            toast.success(res?.message);
        } else {
            toast.error(res?.message);
        }
    };

    // useeffect
    useEffect(() => {
        getUserDetails();
    }, []);

    useEffect(() => {
        const user_type =
            user_info.user_type === 'merchant'
                ? 'merchant'
                : user_info.user_type === 'employee'
                ? 'employee'
                : user_info.user_type === 'support'
                ? 'user'
                : user_info.user_type === 'admin'
                ? 'admin'
                : user_info.user_type === 'implementer'
                ? 'implementer'
                : 'auditor';

        // set loading icon on start of fetch request
        setLoading({ ...loading, content: true });
        GetDownloadLink('user_mgt', user_type, user_info.organization.id)()
            .then((res) => {
                if (res.success) {
                    setLoading({ ...loading, content: false });
                    setImageLink(res.data);
                } else {
                    setImageLink('');
                }
            })
            .catch(() => toast.error('Error getting image'));
    }, [user_info?.picture]);

    return (
        <Box sx={{ p: 4 }}>
            <AppAvatar
                id="pfp"
                src={imageLink || pfp}
                editable
                onChange={changePhoto}
                loading={loading?.upload}
                imageFileRef={imageInputRef}
            />
            <Box sx={{ maxWidth: '500px' }}>
                <AppForm
                    initialValues={{
                        first_name: user_info?.first_name,
                        last_name: user_info?.last_name,
                        email: user_info?.email,
                        username: user_info?.username,
                        title: user_info?.title,
                    }}
                    onSubmit={updateUserDetails}
                    validate={userValidation}
                >
                    <AppFormInput
                        type="text"
                        label={t('userInfo.inputLabel1')}
                        placeholder={t('userInfo.inputLabel1')}
                        name="first_name"
                    />
                    <AppFormInput
                        type="text"
                        label={t('userInfo.inputLabel2')}
                        placeholder={t('userInfo.inputLabel2')}
                        name="last_name"
                    />
                    <AppFormInput
                        type="text"
                        label={t('userInfo.inputLabel3')}
                        placeholder={t('userInfo.inputLabel3')}
                        name="title"
                    />
                    <AppFormInput
                        type="email"
                        label={t('userInfo.inputLabel4')}
                        placeholder={t('userInfo.inputLabel4')}
                        name="email"
                        disabled
                    />
                    <AppFormInput
                        type="text"
                        label={t('userInfo.inputLabel5')}
                        placeholder={t('userInfo.inputLabel5')}
                        name="username"
                        disabled
                    />
                    <AppSubmitButton
                        text={t('userInfo.update')}
                        variant="contained"
                        color="primary"
                        loadingPosition="center"
                        loading={loading.submit}
                        sx={{ mt: 3 }}
                    />
                </AppForm>
            </Box>
        </Box>
    );
};
const mapStateToProps = (state) => {
    return {
        user_info: state?.generalReducers?.user_info,
    };
};
export default connect(mapStateToProps, {
    ChangePhoto,
    GetUserDetails,
    UpdateUserDetails,
})(UserInfo);
