import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Divider, Typography, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { FiTrash2 } from 'react-icons/fi';

//redux
import { connect } from 'react-redux';

//core components
import AppViewModal from 'components/new_components/AppViewModal';
import { GetMerchantEvidenceVersions } from 'store/actions/complianceActions';
import { toast } from 'react-toastify';
import FileTypes from 'assets/img/Filetypes.svg';
import Docx from 'assets/img/docx.svg';
import Png from 'assets/img/png.svg';
import Xsl from 'assets/img/xsl.svg';
import Img from 'assets/img/img.svg';
import Download from 'assets/img/download01.svg';
// import moment from 'moment';
import { DeleteMerchantEvidence } from 'store/actions/complianceActions';
import AppDeleteModal from 'components/new_components/AppDeleteModal';

//translations
import { useTranslation } from 'react-i18next';
import { UpdateMerchantEvidence } from 'store/actions/complianceActions';
import { GetDownloadLink } from 'store/actions/generalActions';

const EvidenceHistoryModal = (props) => {
    //props
    const {
        open,
        handleClose,
        GetMerchantEvidenceVersions,
        document,
        selected,
        doc_id,
        DeleteMerchantEvidence,
        evidence_type,
        evidence,
        openEvidenceModal,
        all_merchant_evidences,
        UpdateMerchantEvidence,
        control,
        standard,
    } = props;

    // state
    const [loading, setLoading] = useState({ versions: false, messages: false, delete: false });
    const [policy_id, setPolicyid] = useState();
    const [deleteModal, setDeleteModal] = useState(false);
    const [downloadLinks, setDownloadLinks] = useState([]);

    // const handleDeleteOpen = (row) => {
    //     setPolicyid(row);
    //     setDeleteModal(true);
    // };

    const handleDeleteClose = () => {
        setPolicyid(null);
        setDeleteModal(false);
        handleClose();
    };

    // mui
    const theme = useTheme();

    //translation
    const { t } = useTranslation('compliance');

    // async functions
    const getDocumentVersions = async () => {
        setLoading({ ...loading, versions: true });
        const res = await GetMerchantEvidenceVersions(selected?.evidence_id);
        setLoading({ ...loading, versions: false });
        if (!res?.success && document?.doc_id !== undefined) {
            toast.error(res?.message);
        }
    };

    useEffect(() => {
        if (open && selected?.evidence_id === doc_id) {
            getDocumentVersions();
        }
    }, [open, selected, doc_id]);

    const handleVersionDelete = async () => {
        setLoading({ ...loading, delete: true });
        const res = await DeleteMerchantEvidence(policy_id.id, policy_id.document_id, standard);
        setLoading({ ...loading, false: true });
        if (res?.success) {
            toast.success('Verison deleted successfuly!');
            handleDeleteClose();
        } else {
            toast.error('Something went wrong!');
            console.log(res?.message);
        }
    };

    const get_current_evidence = all_merchant_evidences?.find((item) => item?.evidence_id === evidence?.id);

    const removeDocument = async (segment, index, evidences) => {
        const one_evidence = all_merchant_evidences?.find((evidence) => evidence?.evidence_id === doc_id);
        evidences?.splice(index, 1);
        const data = [{ [segment]: evidences }];
        const doc = new FormData();
        doc.append('data', data);
        doc.append('evidence_id', doc_id);

        const body = {
            data,
            evidence_id: doc_id,
        };

        const res = await UpdateMerchantEvidence(body, control, one_evidence?.id);
        if (res?.success) {
            toast.success('Document deleted successfully');
        } else {
            toast.error(res?.message);
        }
    };

    const segments_in_evidence = useMemo(
        () => get_current_evidence?.data?.map((el) => Object.keys(el)?.[0]),
        [get_current_evidence]
    );
    const getDownloadLinks = useCallback(async () => {
        setLoading({ ...loading, versions: true });
        const res = await GetDownloadLink('compliance', 'user_evidence', get_current_evidence?.id, true)();
        setLoading({ ...loading, versions: false });
        if (res?.success) {
            const myUrls = {};
            segments_in_evidence?.forEach((segment) => {
                const segmentedArray = res?.data
                    ?.filter((urlSegment) => segment.toLowerCase() in urlSegment)
                    .map((el) => el[segment]);

                myUrls[segment] = segmentedArray;
            });
            setDownloadLinks(myUrls);
        } else {
            toast.error('Something went wrong!');
        }
    }, [get_current_evidence?.id]);

    useEffect(() => {
        if (open && evidence && get_current_evidence) {
            getDownloadLinks();
        } else {
            setDownloadLinks([]);
        }
    }, [open, all_merchant_evidences, evidence, get_current_evidence]);

    return (
        <AppViewModal open={open} handleClose={handleClose} width={'520px !important'}>
            <Box sx={{ my: 1.5 }}>
                <Box sx={{ display: 'flex', mx: 1.5, pb: 2, alignItems: 'center' }}>
                    <ArrowBackIosIcon
                        onClick={handleClose}
                        sx={{ width: 20, height: 20, color: '#000000', cursor: 'pointer' }}
                    />
                    <Typography sx={{ fontSize: '16px', fontWeight: 600, color: '#202D66', ml: 2 }}>
                        {evidence?.soc2_title ||
                            evidence?.lifecycle_title ||
                            evidence?.quality_mgt_title ||
                            evidence?.compliance_mgt_title ||
                            evidence?.card4_title}
                    </Typography>
                </Box>
                <Divider sx={{ color: theme.palette.neutral[95] }} />
            </Box>
            {evidence?.segments?.length > 0 ? (
                <Box sx={{ mt: 2, mx: 3 }}>
                    {(evidence_type === 'soc2' ? evidence?.segments.slice(0, 1) : evidence?.segments)?.map(
                        (segment, index) => {
                            const get_current_docs = get_current_evidence?.data?.find((item, idx) => {
                                const find_current = Object.keys(item).find((obj) => item[obj]);
                                const find_existing = Object.keys(segment).find((obj) => segment[obj]);
                                const new_evidence = find_current === find_existing ? item?.[`segment_${idx + 1}`] : [];
                                return new_evidence;
                            });

                            return (
                                <Box key={index} sx={{ py: 1 }}>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            mb: 3,
                                        }}
                                    >
                                        <Typography
                                            sx={{ color: theme.palette.primary[30], fontSize: '13px', fontWeight: 700 }}
                                        >
                                            {evidence?.tags === 'sslc'
                                                ? 'Upload Evidences'
                                                : evidence?.tags === 'card4'
                                                ? 'Upload Evidences'
                                                : `Period ${index + 1}`}
                                            :
                                        </Typography>
                                        {get_current_docs?.[`segment_${index + 1}`]?.length >
                                        segment?.[`segment_${index + 1}`] ? (
                                            <Button
                                                sx={{
                                                    textTransform: 'inherit',
                                                    fontSize: '13px',
                                                    backgroundColor: '#F8FAFC',
                                                    color: '#395BA9',
                                                    border: '1px solid #E2E8F0',
                                                    ml: 1,
                                                    py: 0.3,
                                                    px: 1,
                                                    '&:hover': {
                                                        backgroundColor: '#F8FAFC !important',
                                                        border: '1px solid #E2E8F0',
                                                    },
                                                }}
                                                onClick={() =>
                                                    openEvidenceModal(
                                                        `segment_${index + 1}`,
                                                        segment?.[`segment_${index + 1}`]
                                                    )
                                                }
                                                startIcon={
                                                    <FiberManualRecordIcon
                                                        sx={{
                                                            width: 10,
                                                            height: 10,
                                                            color: '#55BE8B',
                                                        }}
                                                    />
                                                }
                                            >
                                                {get_current_docs?.[`segment_${index + 1}`]?.length ===
                                                segment?.[`segment_${index + 1}`]
                                                    ? 'Completed'
                                                    : get_current_docs?.[`segment_${index + 1}`]?.length >
                                                      segment?.[`segment_${index + 1}`]
                                                    ? 'Completed'
                                                    : ' Upload Evidence'}{' '}
                                                ({get_current_docs?.[`segment_${index + 1}`]?.length})
                                            </Button>
                                        ) : (
                                            <Button
                                                sx={{
                                                    textTransform: 'inherit',
                                                    fontSize: '13px',
                                                    backgroundColor: '#F8FAFC',
                                                    color:
                                                        get_current_docs?.[`segment_${index + 1}`]?.length ===
                                                        segment?.[`segment_${index + 1}`]
                                                            ? '#395BA9'
                                                            : '#395BA9',
                                                    border: '1px solid #E2E8F0',
                                                    ml: 1,
                                                    py: 0.3,
                                                    px: 1,
                                                    '&:hover': {
                                                        backgroundColor: '#F8FAFC !important',
                                                        border: '1px solid #E2E8F0',
                                                    },
                                                }}
                                                onClick={() =>
                                                    openEvidenceModal(
                                                        `segment_${index + 1}`,
                                                        segment?.[`segment_${index + 1}`]
                                                    )
                                                }
                                                startIcon={
                                                    <FiberManualRecordIcon
                                                        sx={{
                                                            width: 10,
                                                            height: 10,
                                                            color:
                                                                get_current_docs?.[`segment_${index + 1}`]?.length ===
                                                                segment?.[`segment_${index + 1}`]
                                                                    ? '#55BE8B'
                                                                    : get_current_docs?.[`segment_${index + 1}`]
                                                                          ?.length === segment?.[`segment_${index + 1}`]
                                                                    ? '#55BE8B'
                                                                    : theme.palette.neutral[500],
                                                        }}
                                                    />
                                                }
                                            >
                                                {get_current_docs?.[`segment_${index + 1}`]?.length ===
                                                segment?.[`segment_${index + 1}`]
                                                    ? 'Completed'
                                                    : get_current_docs?.[`segment_${index + 1}`]?.length >
                                                      segment?.[`segment_${index + 1}`]
                                                    ? 'Completed'
                                                    : ' Upload Evidence'}{' '}
                                                ({get_current_docs?.[`segment_${index + 1}`]?.length || 0}/
                                                {segment?.[`segment_${index + 1}`]})
                                            </Button>
                                        )}
                                    </Box>
                                    <Divider sx={{ color: theme.palette.neutral[95], mt: 1, mx: -1.5 }} />
                                    {get_current_docs?.[`segment_${index + 1}`]?.length > 0 ? (
                                        <Box>
                                            {get_current_docs?.[`segment_${index + 1}`]?.map((evidence, i) => (
                                                <Box
                                                    key={i}
                                                    sx={{
                                                        display: 'flex',
                                                        justifyContent: 'space-between',
                                                        alignItems: 'center',
                                                        py: 3,
                                                    }}
                                                >
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            justifyContent: 'space-between',
                                                            alignItems: 'center',
                                                        }}
                                                    >
                                                        <img
                                                            src={
                                                                evidence?.split('.').pop() === 'xlsx'
                                                                    ? Xsl
                                                                    : evidence?.split('.').pop() === 'csv'
                                                                    ? Xsl
                                                                    : evidence?.split('.').pop() === 'docx'
                                                                    ? Docx
                                                                    : evidence?.split('.').pop() === 'png'
                                                                    ? Png
                                                                    : evidence?.split('.').pop() === 'img'
                                                                    ? Img
                                                                    : FileTypes
                                                            }
                                                            alt="FileTypes"
                                                        />
                                                        <Typography
                                                            component="p"
                                                            sx={{
                                                                color: '#475569',
                                                                fontSize: '14px',
                                                                fontWeight: 400,
                                                                maxWidth: '300px !important',
                                                                wordWrap: 'break-word',
                                                                ml: 1,
                                                            }}
                                                        >
                                                            {evidence?.split('/').pop()}
                                                        </Typography>
                                                    </Box>{' '}
                                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                        <FiTrash2
                                                            color="#F43F5E"
                                                            sx={{ cursor: 'pointer' }}
                                                            onClick={() =>
                                                                removeDocument(
                                                                    `segment_${index + 1}`,
                                                                    i,
                                                                    get_current_docs?.[`segment_${index + 1}`]
                                                                )
                                                            }
                                                        />
                                                        <Box
                                                            component="a"
                                                            href={downloadLinks?.[`segment_${index + 1}`]?.[i]}
                                                            rel="noreferrer"
                                                            target="_blank"
                                                            sx={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                ml: 2,
                                                                opacity: !downloadLinks?.[`segment_${index + 1}`]?.[i]
                                                                    ? 0.15
                                                                    : 1,
                                                                pointerEvents: !downloadLinks?.[
                                                                    `segment_${index + 1}`
                                                                ]?.[i]
                                                                    ? 'none'
                                                                    : 'initial',
                                                            }}
                                                        >
                                                            <img src={Download} alt={'Download'} />
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            ))}
                                            {get_current_docs?.[`segment_${index + 1}`]?.length > 0 && (
                                                <Divider sx={{ color: theme.palette.neutral[95], mt: 1, mx: -1.5 }} />
                                            )}
                                        </Box>
                                    ) : (
                                        <Box>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    mt: 5,
                                                }}
                                            >
                                                <Typography>No uploads yet</Typography>
                                            </Box>
                                            <Divider sx={{ color: theme.palette.neutral[95], mt: 1, mx: -1.5 }} />
                                        </Box>
                                    )}
                                </Box>
                            );
                        }
                    )}
                </Box>
            ) : (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: '4rem' }}>
                    <Typography sx={{ color: '#64748B', fontSize: '16px', fontWeight: 600 }}>
                        No evidence found
                    </Typography>
                </Box>
            )}

            <AppDeleteModal
                open={deleteModal}
                handleClose={handleDeleteClose}
                title={t('title')}
                subtitle={t('subtitle')}
                deleting={loading?.delete}
                onDelete={handleVersionDelete}
            />
        </AppViewModal>
    );
};

const mapStateToProps = (state) => {
    return {
        evidence_versions: state?.complianceReducers?.merchant_evidence_versions,
        all_merchant_evidences: state?.complianceReducers?.all_merchant_evidences,
    };
};
export default connect(mapStateToProps, {
    GetMerchantEvidenceVersions,
    DeleteMerchantEvidence,
    UpdateMerchantEvidence,
})(EvidenceHistoryModal);
