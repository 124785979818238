import React, { useMemo, useState } from 'react';
import DoubleRightCheck from 'assets/img/chevron-right-double.svg';
import SmartIntelLogo from 'assets/img/Smartcomply Intel fav 1.svg';
import ArrowRight from 'assets/img/arrow-up-right.svg';
import Carousel from 'components/new_components/carousel/Carousel';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { formatNumber } from '../utils/index';
import { useHistory } from 'react-router-dom';
import { Box } from '@mui/material';
import { useSelector } from 'react-redux';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const LocalAssets = (props) => {
    const { switch_on, handleOpen } = props;
    const [tab, setTab] = useState('risk_classification');
    const router = useHistory();

    const all_vendors = useSelector((state) => state?.vendorReducers?.all_vendors);
    const { all_smart_intel } = useSelector((state) => state?.generalReducers);
    const gotoPage = (link) => {
        router.push(link);
    };

    const low_values = all_vendors?.filter((item) => item?.overall_risk_level === 'low')?.length;
    const medium_values = all_vendors?.filter((item) => item?.overall_risk_level === 'medium')?.length;
    const high_values = all_vendors?.filter((item) => item?.overall_risk_level === 'high')?.length;
    const critical_values = all_vendors?.filter((item) => item?.overall_risk_level === 'critical')?.length;

    const values = [low_values, medium_values, high_values, critical_values];
    const vendor_responded = all_vendors?.filter((response) => response?.has_responded)?.length;
    const vendor_not_responded = all_vendors?.filter((response) => !response?.has_responded)?.length;
    const responded_percentage = (vendor_responded * 100) / all_vendors?.length;

    const maxValue = useMemo(
        () =>
            values.reduce((max, item) => {
                return item > max ? item : max;
            }, 0),
        [values]
    );
    const MAX_ITEMS = 5;

    const data = {
        labels: ['Low', 'Medium', 'high', 'Critical'],
        datasets: [
            {
                data: values,
                backgroundColor: '#395BA9',
                borderWidth: 0,
                borderRadius: 4,
            },
        ],
    };

    // const maxValue = useMemo(
    //     () =>
    //         [20, 40, 60, 80].reduce((max, item) => {
    //             return item > max ? item : max;
    //         }, 0),
    //     [[20, 40, 60, 80]]
    // );
    // const MAX_ITEMS = 5;

    const options = useMemo(
        () => ({
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
                legend: {
                    display: false,
                },
                title: {
                    display: true,
                    font: {
                        size: 18,
                    },
                },
                tooltip: {
                    callbacks: {
                        label: function (context) {
                            const month =
                                ['Low', 'Medium', 'high', 'Critical'].filter((el) =>
                                    el?.toLowerCase().startsWith(context.label?.toLowerCase())
                                )[0] ?? '';
                            return month;
                        },
                        title: function (context) {
                            return context[0].raw;
                        },
                    },
                    titleColor: '#ffffff',
                    font: {
                        weight: 'bold',
                        family: 'Poppins, sans-serif',
                    },
                    bodyFont: {
                        weight: 'normal',
                        family: 'Poppins, sans-serif',
                    },
                },
            },
            scales: {
                x: {
                    stacked: false,
                    title: {
                        display: false,
                        text: 'Month',
                    },
                    grid: {
                        display: false,
                    },
                    ticks: {
                        color: '#64748B',
                        font: { family: 'Poppins, sans-serif' },
                    },
                    border: {
                        display: false,
                    },
                    barPercentage: 1.0,
                    categoryPercentage: 1.0,
                },
                y: {
                    min: Math.min([...values]),
                    stacked: false,
                    // title: {
                    //     display: true,
                    //     text: `Amount loss(${filterCurrency})`,
                    //     color: '#475569',
                    //     font: {
                    //         weight: 'bolder',
                    //         family: 'Poppins, sans-serif',
                    //     },
                    // },
                    grid: {
                        display: false,
                    },
                    border: {
                        display: false,
                    },
                    ticks: {
                        stepSize: Math.round(maxValue / MAX_ITEMS),
                        color: '#64748B',
                        font: { family: 'Poppins, sans-serif' },
                        callback: (value) => formatNumber(value),
                    },
                },
            },
        }),
        []
    );

    const merchant_info = useSelector((state) => state?.merchantReducers?.merchant_info);
    const keywords = merchant_info?.intel_keywords?.['Third-Parties'];

    const intel =
        switch_on && all_smart_intel?.['Third-Parties']
            ? Object?.values(all_smart_intel?.['Third-Parties'])
                  ?.map((option) => option)
                  ?.flat()
                  ?.filter((el) => {
                      return keywords?.some(
                          (keyword) =>
                              el?.title?.toLowerCase()?.includes(keyword) ||
                              el?.market_place?.toLowerCase()?.includes(keyword)
                      );
                  })
            : [];
    function RenderTab() {
        switch (tab) {
            case 'risk_classification':
                return (
                    <div className="w-full mt-5 flex justify-center items-center">
                        <div className=" mt-5 xl:w-[60%] md:w-full">
                            <Box sx={{ mt: 3 }}>
                                <div>
                                    <Bar data={data} options={options} />
                                </div>
                            </Box>
                        </div>
                    </div>
                );
            case 'questionaire':
                return (
                    <div className="flex items-center gap-10">
                        <div className="relative w-full xl:mt-5 xl:flex justify-center items-center md:block">
                            <div className="absolute top-5 xl:left-[15%] md:left-[30%]">
                                <div className="absolute xl:w-[190px] xl:h-[190px] md:w-[160px] md:h-[160px] rounded-full bg-[#B1C5FF]">
                                    <div
                                        className={`absolute rounded-full w-[70px] h-[70px] xl:border-[110px] md:border-[90px] border-transparent rotate-45 top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 transform
                   
                    ${
                        responded_percentage > 95
                            ? 'border-y-[#395BA9] border-x-[#395BA9]'
                            : responded_percentage > 75
                            ? 'border-y-[#395BA9] border-r-[#395BA9]'
                            : responded_percentage > 65
                            ? 'border-y-[#395BA9] border-r-[#395BA9]'
                            : responded_percentage > 33
                            ? 'border-t-[#395BA9] border-r-[#395BA9]'
                            : responded_percentage > 25
                            ? 'border-t-[#395BA9] border-r-[#395BA9]'
                            : responded_percentage === 0
                            ? 'border-y-[#B1C5FF] border-x-[#B1C5FF]'
                            : 'border-t-[#395BA9]'
                    }`}
                                    >
                                        <div className="bg-white rounded-full w-[120px] h-[120px] flex justify-center items-center -translate-y-1/2 -translate-x-1/2 transform -rotate-45">
                                            <p className="text-[#202D66] font-bold text-[34px]">
                                                {Math.round(all_vendors?.length)}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="xl:mt-[5rem] md:mt-[14rem] xl:ml-[15rem] md:ml-0 xl:block md:flex justify-center items-center flex-col">
                                <div className="flex items-center gap-3">
                                    <div className="w-[20px] h-[20px] bg-[#395BA9] rounded-full"></div>
                                    <p className="text-[#475569] text-sm font-semibold">Responded</p>
                                    <div className="w-[20px] h-[20px] bg-[#94A3B8] rounded p-0.5 flex justify-center items-center">
                                        <p className="text-white text-xs font-medium">{vendor_responded}</p>
                                    </div>
                                </div>
                                <div className="flex items-center gap-3 mt-[1rem]">
                                    <div className="w-[20px] h-[20px] bg-[#B1C5FF] rounded-full"></div>
                                    <p className="text-[#475569] text-sm font-semibold">Not Responded</p>
                                    <div className="w-[20px] h-[20px] bg-[#94A3B8] rounded p-0.5 flex justify-center items-center">
                                        <p className="text-white text-xs font-medium">{vendor_not_responded}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            default:
                return (
                    <div className="w-full mt-5 flex justify-center items-center">
                        <div className=" mt-5 w-[60%]">
                            <Box sx={{ mt: 3 }}>
                                <div>
                                    <Bar data={data} options={options} />
                                </div>
                            </Box>
                        </div>
                    </div>
                );
        }
    }
    return (
        <div className="bg-white border-1 border-[#F1F5F9] rounded-md p-3">
            <div className="flex justify-between items-center">
                <h4 className="text-[#2B3674] text-lg font-semibold">Vendors</h4>
                <button className="bg-[#F1F5F9] py-1 px-2 rounded-md" onClick={() => gotoPage('/merchant/aml/vendors')}>
                    <img src={DoubleRightCheck} alt="DoubleRightCheck" />
                </button>
            </div>
            <div className="mt-[1.5rem] bg-[#F1F5F9] rounded p-2.5 flex justify-between items-center gap-2 w-full">
                <div
                    className={`${
                        tab === 'risk_classification' ? 'bg-white shadow-md transition ease-in-out duration-500' : ''
                    } p-2 flex justify-center items-center w-[50%] rounded cursor-pointer`}
                    onClick={() => setTab('risk_classification')}
                >
                    <p
                        className={`${
                            tab === 'risk_classification' ? 'text-[#395BA9]' : 'text-[#64748B]'
                        } text-sm font-bold`}
                    >
                        Vendor risk classification
                    </p>
                </div>
                <div
                    className={`${
                        tab === 'questionaire' ? 'bg-white shadow-md transition ease-in-out duration-500' : ''
                    } p-2 flex justify-center items-center w-[50%] rounded cursor-pointer`}
                    onClick={() => setTab('questionaire')}
                >
                    <p className={`${tab === 'questionaire' ? 'text-[#395BA9]' : 'text-[#64748B]'} text-sm font-bold`}>
                        Questionnaire status
                    </p>
                </div>
            </div>

            {switch_on && intel.length && (
                <div className=" my-[1.5rem] transition ease-in-out duration-500">
                    <div className="bg-[#E5F1FF] rounded-full px-3 py-2 flex justify-between items-center w-full 2xl:h-[50px] xl:h-[70px] md:[100px]">
                        <div className="flex items-center gap-2 w-[85%]">
                            <img src={SmartIntelLogo} alt="SmartIntelLogo" className="xl:block md:hidden" />
                            <p className="bg-[#F43F5E] text-white text-[13px] rounded px-1 py-0.5 w-fit xl:block md:hidden">
                                {intel?.length}
                            </p>
                            <Carousel
                                slides={intel}
                                handleOpen={handleOpen}
                                type="Third-Parties"
                                data={all_smart_intel?.['Third-Parties']}
                            />
                        </div>
                        <img src={ArrowRight} alt="ArrowRight" />
                    </div>
                </div>
            )}
            <RenderTab />
        </div>
    );
};

export default LocalAssets;
// border-t-[#395BA9]
// `}
