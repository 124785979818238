import React from 'react';
import { useTheme } from '@mui/material/styles';
import {
    Box,
    Button,
    ButtonGroup,
    Stack,
    Typography,
    // Tooltip
} from '@mui/material';
import FileQuestion from 'assets/img/file-question-01.svg';
// import ComplianceAIIcon from 'assets/img/compliance-ai-icon.svg';
// core components
import { AppFormInput, AppFormSelect } from 'components/new_components/forms';

//translations
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import AppInput from 'components/new_components/AppInput';

const FieldItem = (props) => {
    const theme = useTheme();
    const {
        field,
        number,
        handleInputFocus,
        // openSuggestionModal, isAIOn, focusedInput,
        standard,
        formPayload,
        setFormPayload,
        error,
    } = props;

    //translation
    const language = localStorage.getItem('i18nextLng');

    const user_type = useSelector((state) => state?.authReducers?.user_type);
    return (
        <Box sx={{ mt: 4 }}>
            <Stack direction="row" alignItems={'start'} spacing={1}>
                <>
                    {standard === 'soc2' || standard === 'sslc' || standard === 'iso9001' || standard === 'iso37301' ? (
                        <img src={FileQuestion} alt="icon" />
                    ) : (
                        <Box
                            sx={{
                                width: 28,
                                height: 28,
                                minWidth: 28,
                                minHeight: 28,
                                borderRadius: '50%',
                                backgroundColor: theme.palette.primary[900],
                                color: theme.palette.white.main,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                fontSize: 14,
                                fontWeight: 700,
                            }}
                        >
                            {number}
                        </Box>
                    )}
                </>
                <Typography
                    sx={{
                        color: '#64748B',
                        fontWeight: 400,
                        // maxWidth: 750,
                        fontSize: '14px',
                    }}
                >
                    {language?.includes('fr') ? field.name_fr : language?.includes('en') ? field.name_en : field.name}
                </Typography>
            </Stack>
            <Stack direction="row" alignItems="end" sx={{ width: 500 }}>
                {field?.type === 'dropdown' &&
                (standard === 'sslc' || standard === 'iso9001' || standard === 'iso37301') ? (
                    <ButtonGroup sx={{ mt: 2 }}>
                        {field?.options?.split(',')?.map((option, index) => (
                            <Button
                                onClick={() => {
                                    setFormPayload({
                                        ...formPayload,
                                        [field?.id]: option,
                                    });
                                }}
                                key={index}
                                sx={{
                                    backgroundColor:
                                        formPayload?.[field.id] === option.replace(' ', '')
                                            ? '#202D66'
                                            : formPayload?.[field.id] === option.replace('  ', '')
                                            ? '#202D66'
                                            : '',
                                    color:
                                        formPayload?.[field.id] === option.replace(' ', '')
                                            ? '#FFFFFF'
                                            : formPayload?.[field.id] === option.replace('  ', '')
                                            ? '#FFFFFF'
                                            : '#64748B',
                                    border:
                                        formPayload?.[field.id] === option.replace(' ', '')
                                            ? 'none'
                                            : formPayload?.[field.id] === option.replace('  ', '')
                                            ? 'none'
                                            : '0.5px solid #E2E8F0',
                                    px: 0.7,
                                    '&:hover': {
                                        backgroundColor:
                                            formPayload?.[field.id] === option.replace(' ', '')
                                                ? '#202D66'
                                                : formPayload?.[field.id] === option.replace('  ', '')
                                                ? '#202D66'
                                                : '',
                                        color:
                                            formPayload?.[field.id] === option.replace(' ', '')
                                                ? '#FFFFFF'
                                                : formPayload?.values?.[field?.id] === option.replace('  ', '')
                                                ? '#FFFFFF'
                                                : '#64748B',
                                        border:
                                            formPayload?.[field.id] === option.replace(' ', '')
                                                ? 'none'
                                                : formPayload?.[field.id] === option.replace('  ', '')
                                                ? 'none'
                                                : '0.5px solid #E2E8F0',
                                    },
                                }}
                            >
                                {option}
                            </Button>
                        ))}
                    </ButtonGroup>
                ) : (field?.type === 'text' ||
                      field?.type === 'number' ||
                      field?.type === 'textarea' ||
                      field?.type === 'date' ||
                      field?.type === 'time') &&
                  (standard === 'sslc' || standard === 'iso9001' || standard === 'iso37301') ? (
                    <AppInput
                        name={field.name}
                        type={field.type}
                        value={formPayload?.[field.id]}
                        placeholder={field?.type}
                        multiline={field?.type === 'textarea'}
                        rows={field?.type === 'textarea' ? 3 : 0}
                        onChange={(event) =>
                            setFormPayload({
                                ...formPayload,
                                [field.id]: event?.target?.value,
                            })
                        }
                    />
                ) : (
                    <Field
                        field={field}
                        name={field?.id?.toString()}
                        handleInputFocus={handleInputFocus}
                        standard={standard}
                        disabled={user_type === 'auditor'}
                    />
                )}
            </Stack>

            {error && formPayload?.[field.id] === '' && (
                <p className="text-[#FF0000] text-[12px] mt-2">Field is required</p>
            )}
        </Box>
    );
};

const Field = (props) => {
    const { field, name, handleInputFocus, standard, disabled } = props;

    //translation
    const { t } = useTranslation('compliance');
    const language = localStorage.getItem('i18nextLng');

    return field?.type === 'text' ? (
        <AppFormInput
            type="text"
            name={name}
            placeholder={t('response')}
            onClick={() => {
                handleInputFocus(name);
            }}
            disabled={disabled}
        />
    ) : field?.type === 'number' ? (
        <AppFormInput
            type="number"
            name={name}
            placeholder={t('number')}
            onClick={() => {
                handleInputFocus(name);
            }}
            disabled={disabled}
        />
    ) : field?.type === 'textarea' ? (
        <AppFormInput
            type="text"
            name={name}
            placeholder={t('details')}
            multiline
            onClick={() => {
                handleInputFocus(name);
            }}
            disabled={disabled}
        />
    ) : field?.type === 'date' ? (
        <AppFormInput
            type="date"
            name={name}
            onClick={() => {
                handleInputFocus(name);
            }}
            disabled={disabled}
        />
    ) : field?.type === 'time' ? (
        <AppFormInput
            type="time"
            name={name}
            onClick={() => {
                handleInputFocus(name);
            }}
            disabled={disabled}
        />
    ) : field?.type === 'dropdown' ? (
        <AppFormSelect
            name={name}
            options={
                standard === 'gdpa' ||
                standard === 'kdpa' ||
                standard === 'isms' ||
                standard === 'tech_sec' ||
                standard === 'sslc' ||
                standard === 'iso9001' ||
                standard === 'iso37301' ||
                standard === 'soc2' ||
                standard === 'pcidss'
                    ? field?.options?.split(',') || []
                    : language?.includes('fr')
                    ? field?.options_fr?.split(',') || []
                    : language?.includes('en')
                    ? field?.options_en?.split(',') || []
                    : field?.options?.split(',') || []
            }
            defaultValue={t('select')}
            onOpen={() => {
                handleInputFocus(name);
            }}
            disabled={disabled}
        />
    ) : null;
};

export default FieldItem;
