import React, { useMemo, useState } from 'react';
import { Box, Button, Divider, IconButton, Menu, MenuItem, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import File from 'assets/img/file4.svg';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

//redux
import { connect } from 'react-redux';

//core components
import AppViewModal from 'components/new_components/AppViewModal';
import EvidenceHistoryModal from './EvidenceHistoryModal';
import EvidenceHistory from './EvidenceHistory';
import UploadEvidenceModal from './UploadEvidenceModal2';
import { getType } from 'utils';
import { MoreHoriz } from '@mui/icons-material';

const EvidencesDetailModal = (props) => {
    //props
    const { open, handleClose, title, subgroup_evidences, all_merchant_evidences, evidence_type, category, standard } =
        props;
    const [evidenceHistoryModal, setEvidenceHistoryModal] = useState(false);
    const [evidenceHistory, setEvidenceHistory] = useState(false);
    const [doc_id, setDoc_id] = useState();
    const [evidence, setEvidence] = useState(null);
    const [segment, setSegment] = useState('');
    const [segmentNumber, setSegmentNumber] = useState();
    const [uploadEvidenceModal, setUploadEvidenceModal] = useState(false);
    const [anchor, setAnchor] = useState(null);

    const role = getType('role');

    const openMenu = (e) => {
        e.stopPropagation();
        setAnchor(e.target);
    };
    const closeMenu = () => setAnchor(null);

    const openEvidenceHistoryModal = (evidence) => {
        setEvidence(evidence);
        setDoc_id(evidence?.id);
        setEvidenceHistoryModal(true);
    };

    const closeEvidenceHistoryModal = () => {
        setEvidenceHistoryModal(false);
    };

    const openEvidenceHistory = (evidence) => {
        closeMenu();
        setEvidence(evidence);
        setDoc_id(evidence?.id);
        setEvidenceHistory(true);
    };

    const closeEvidenceHistory = () => {
        setEvidenceHistory(false);
    };

    const selectedEvidence = useMemo(() => {
        return all_merchant_evidences?.find((doc) => doc?.evidence_id === doc_id);
    }, [doc_id, all_merchant_evidences]);

    const openEvidenceModal = (item, number) => {
        setSegment(item);
        setSegmentNumber(number);
        setUploadEvidenceModal(true);
    };

    const closeEvidenceModal = () => {
        setUploadEvidenceModal(false);
    };

    const theme = useTheme();
    return (
        <AppViewModal open={open} handleClose={handleClose} width={520}>
            <Box sx={{ my: 1.5 }}>
                <Box sx={{ display: 'flex', mx: 3, pb: 2, alignItems: 'flex-start' }}>
                    <ArrowBackIosIcon
                        onClick={handleClose}
                        sx={{ width: 20, height: 20, color: '#000000', cursor: 'pointer', mt: 0.5 }}
                    />
                    <Box sx={{ ml: 1.5 }}>
                        <Typography sx={{ fontSize: '16px', fontWeight: 600, color: '#202D66' }}>Evidences</Typography>
                        <Typography sx={{ fontSize: '14px', fontWeight: 600, color: '#64748B' }}>
                            {standard === 'sslc' ? 'CO' : ''}
                            {title}
                        </Typography>
                    </Box>
                </Box>
                <Divider sx={{ color: theme.palette.neutral[95] }} />
                {subgroup_evidences?.length ? (
                    <Box>
                        {subgroup_evidences?.map((evidence, index) => {
                            const selectedEvidence = all_merchant_evidences?.find(
                                (doc) => doc?.evidence_id === evidence?.id
                            );

                            const filtered_doc_numb = (
                                selectedEvidence?.data?.find((itm, index) => itm?.[`segment_${index + 1}`])
                                    ? Object?.values(
                                          selectedEvidence?.data?.find((itm, index) => itm?.[`segment_${index + 1}`])
                                      )
                                    : []
                            )?.reduce((prev, current) => {
                                const reduced_items = prev + current?.length;
                                return reduced_items;
                            }, 0);
                            const expected_doc_number = (
                                evidence_type === 'soc2' ? evidence?.segments?.slice(0, 1) : evidence?.segments
                            )
                                ?.map((segment, index) => segment?.[`segment_${index + 1}`])
                                ?.reduce((prev, current) => {
                                    const reduced_items = prev + current;
                                    return reduced_items;
                                }, 0);
                            return (
                                <Box key={index} sx={{ m: 3 }}>
                                    <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
                                        <img src={File} alt="File" className="mt-[2px]" />
                                        <Box sx={{ ml: 1 }}>
                                            <Typography sx={{ color: '#395BA9', fontWeight: 500, fontSize: '13px' }}>
                                                {evidence?.soc2_title ||
                                                    evidence?.lifecycle_title ||
                                                    evidence?.quality_mgt_title ||
                                                    evidence?.compliance_mgt_title}
                                                :{' '}
                                                <Typography
                                                    component="span"
                                                    sx={{ color: '#64748B', fontSize: '13px', fontWeight: 400 }}
                                                >
                                                    {evidence?.soc2_description ||
                                                        evidence?.lifecycle_description ||
                                                        evidence?.quality_mgt_description ||
                                                        evidence?.compliance_mgt_description}
                                                </Typography>
                                            </Typography>
                                        </Box>
                                    </Box>
                                    {standard === 'sslc' ? (
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'flex-end',
                                                alignItems: 'center',
                                                gap: 2,
                                            }}
                                        >
                                            {filtered_doc_numb > expected_doc_number ? (
                                                <Button
                                                    startIcon={
                                                        <FiberManualRecordIcon
                                                            sx={{
                                                                width: 10,
                                                                height: 10,
                                                                color:
                                                                    filtered_doc_numb === expected_doc_number ||
                                                                    filtered_doc_numb > expected_doc_number
                                                                        ? '#55BE8B'
                                                                        : '#FF5449',
                                                            }}
                                                        />
                                                    }
                                                    sx={{ fontSize: '13px', fontWeight: 600, color: '#64748B', p: 0.5 }}
                                                >
                                                    ({filtered_doc_numb})
                                                </Button>
                                            ) : (
                                                <Button
                                                    startIcon={
                                                        <FiberManualRecordIcon
                                                            sx={{
                                                                width: 10,
                                                                height: 10,
                                                                color:
                                                                    filtered_doc_numb === expected_doc_number
                                                                        ? '#55BE8B'
                                                                        : '#FF5449',
                                                            }}
                                                        />
                                                    }
                                                    sx={{ fontSize: '13px', fontWeight: 600, color: '#64748B', p: 0.5 }}
                                                >
                                                    {filtered_doc_numb}/{expected_doc_number}
                                                </Button>
                                            )}
                                            {role !== 'auditor' && (
                                                <Typography
                                                    onClick={() => openEvidenceHistoryModal(evidence)}
                                                    sx={{
                                                        textDecoration: 'underline',
                                                        color: '#395BA9',
                                                        fontSize: '13px',
                                                        fontWeight: 600,
                                                        cursor: 'pointer',
                                                    }}
                                                >
                                                    {filtered_doc_numb === expected_doc_number ||
                                                    filtered_doc_numb > expected_doc_number
                                                        ? 'Update Evidence'
                                                        : 'Upload Evidence'}
                                                </Typography>
                                            )}
                                            <>
                                                <IconButton
                                                    sx={{
                                                        color: theme.palette.gray[900],
                                                        minWidth: 'unset',
                                                        height: '25px',
                                                        p: 0,
                                                        border: '1px solid #E1E2EC',
                                                        borderRadius: 1,
                                                    }}
                                                    onClick={openMenu}
                                                >
                                                    <MoreHoriz />
                                                </IconButton>
                                                <Menu
                                                    open={Boolean(anchor)}
                                                    anchorEl={anchor}
                                                    onClose={closeMenu}
                                                    anchorOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'right',
                                                    }}
                                                    transformOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'right',
                                                    }}
                                                >
                                                    {/* <MenuItem
                                                    sx={{ fontSize: 14, color: '#64748B', fontWeight: 500, py: -2 }}
                                                >
                                                    Assign to
                                                </MenuItem> */}
                                                    <MenuItem
                                                        sx={{ fontSize: 14, color: '#64748B', fontWeight: 500, py: -2 }}
                                                        onClick={() => openEvidenceHistory(evidence)}
                                                    >
                                                        View Evidence History
                                                    </MenuItem>
                                                    {/* <MenuItem
                                                    sx={{ fontSize: 14, color: '#64748B', fontWeight: 500, py: -2 }}
                                                >
                                                    Exempt
                                                </MenuItem> */}
                                                </Menu>
                                            </>
                                        </Box>
                                    ) : (
                                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                            {role !== 'auditor' && (
                                                <Typography
                                                    onClick={() => openEvidenceHistoryModal(evidence)}
                                                    sx={{
                                                        textDecoration: 'underline',
                                                        color: '#395BA9',
                                                        fontSize: '13px',
                                                        fontWeight: 600,
                                                        cursor: 'pointer',
                                                    }}
                                                >
                                                    {filtered_doc_numb === expected_doc_number ||
                                                    filtered_doc_numb > expected_doc_number
                                                        ? 'Update Evidence'
                                                        : 'Upload Evidence'}
                                                </Typography>
                                            )}
                                            {filtered_doc_numb > expected_doc_number ? (
                                                <Button
                                                    startIcon={
                                                        <FiberManualRecordIcon
                                                            sx={{
                                                                width: 10,
                                                                height: 10,
                                                                color:
                                                                    filtered_doc_numb === expected_doc_number ||
                                                                    filtered_doc_numb > expected_doc_number
                                                                        ? '#55BE8B'
                                                                        : '#FF5449',
                                                            }}
                                                        />
                                                    }
                                                    sx={{ fontSize: '13px', fontWeight: 600, color: '#64748B' }}
                                                >
                                                    ({filtered_doc_numb})
                                                </Button>
                                            ) : (
                                                <Button
                                                    startIcon={
                                                        <FiberManualRecordIcon
                                                            sx={{
                                                                width: 10,
                                                                height: 10,
                                                                color:
                                                                    filtered_doc_numb === expected_doc_number
                                                                        ? '#55BE8B'
                                                                        : '#FF5449',
                                                            }}
                                                        />
                                                    }
                                                    sx={{ fontSize: '13px', fontWeight: 600, color: '#64748B' }}
                                                >
                                                    {filtered_doc_numb}/{expected_doc_number}
                                                </Button>
                                            )}
                                        </Box>
                                    )}
                                </Box>
                            );
                        })}
                    </Box>
                ) : (
                    <Box sx={{ display: 'flex', justifyContent: 'center', items: 'center', mt: '15rem' }}>
                        <Typography sx={{ fontSize: '16px', fontWeight: 500, color: '#475569' }}>
                            No Evidences Found
                        </Typography>
                    </Box>
                )}
            </Box>
            <EvidenceHistoryModal
                open={evidenceHistoryModal}
                handleClose={closeEvidenceHistoryModal}
                selected={selectedEvidence}
                doc_id={doc_id}
                evidence_type={evidence_type}
                evidence={evidence}
                openEvidenceModal={openEvidenceModal}
                control={category}
                standard={standard}
            />

            <EvidenceHistory
                open={evidenceHistory}
                handleClose={closeEvidenceHistory}
                selected={selectedEvidence}
                doc_id={doc_id}
                evidence_type={evidence_type}
                evidence={evidence}
                openEvidenceModal={openEvidenceModal}
                control={category}
                standard={standard}
            />
            <UploadEvidenceModal
                open={uploadEvidenceModal}
                handleClose={closeEvidenceModal}
                doc_id={doc_id}
                standard={standard}
                control={category}
                evidence_type={evidence_type}
                segment={segment}
                segmentNumber={segmentNumber}
            />
        </AppViewModal>
    );
};

const mapStateToProps = (state) => {
    return {
        all_assigned_task: state?.tasksReducers?.all_assigned_tasks_by_standard,
        user_id: state?.authReducers?.user_info?.employee?.id,
        documents_by_control: state?.adminReducers?.all_admin_docs_by_common_control,
        all_merchant_evidences: state?.complianceReducers?.all_merchant_evidences,
        evidences_metrics: state?.complianceReducers?.all_evidences_metrics,
    };
};
export default connect(mapStateToProps, {})(EvidencesDetailModal);
