import {
    ALL_COMPLIANCE_MANAGEMENT,
    ALL_COMPLIANCE_MANAGEMENT_METRICS,
    ALL_ADMIN_DOCUMENTS_COMPLIANCE_MANAGEMENT,
    ALL_ADMIN_EVIDENCE_BY_QCOMPLIANCE_MANAGEMENT,
    ALL_EXEMPTED_EVIDENCE,
} from '../constants';

export default (state = {}, action) => {
    switch (action.type) {
        case ALL_COMPLIANCE_MANAGEMENT:
            return {
                ...state,
                all_compliance_management: action.payload,
            };
        case ALL_COMPLIANCE_MANAGEMENT_METRICS:
            return {
                ...state,
                all_compliance_management_summary: action.payload,
            };
        case ALL_ADMIN_DOCUMENTS_COMPLIANCE_MANAGEMENT:
            return {
                ...state,
                documents_by_control: action.payload,
            };
        case ALL_ADMIN_EVIDENCE_BY_QCOMPLIANCE_MANAGEMENT:
            return {
                ...state,
                evidence_by_control: action.payload,
            };

        case ALL_EXEMPTED_EVIDENCE:
            return {
                ...state,
                exempted_evidence: action.payload,
            };

        default:
            return state;
    }
};
