import React from 'react';
import { Switch, Route } from 'react-router-dom';
import FormCreation from '../Operations/FormCreation';
import FormsAndDocumentsView from './FormsAndDocumentsView';
import ISO37301Dashboard from './ISO37301Document';
import SubcontrolsView from './SubmanagementView';

const ISO37301 = () => {
    return (
        <Switch>
            <Route path="/admin/iso37301" exact component={ISO37301Dashboard} />
            <Route path="/admin/iso37301/submanagement" exact component={SubcontrolsView} />
            <Route path="/admin/iso37301/submanagement/items" exact component={FormsAndDocumentsView} />
            <Route
                path="/admin/iso37301/submanagement/items/form"
                component={(props) => <FormCreation tag="iso37301" {...props} />}
            />
        </Switch>
    );
};

export default ISO37301;
